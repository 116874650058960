import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Admin from "./layouts/Admin";
import Employer from "./layouts/Employer";
import Home from "./layouts/Home";
import Recruiter from "./layouts/Recruiter";
import Talent from "./layouts/Talent";
import RecruiterPage from "@containers/Home/RecruiterPage/RecruiterPage";
import LandingPage from "@containers/Home/LandingPage/LandingPage";
import TalentSourcing from "@containers/Recruiter/TalentSourcing/TalentSourcing";
import ComingSoon from "@containers/ComingSoon/ComingSoon";
import Auth from "./layouts/Auth";
import SignIn from "@containers/Auth/SignIn/SignIn";
import SignUp from "@containers/Auth/SignUp/SignUp";
import JobHub from "@containers/Talent/JobHub/JobHub";
import { useAuth } from "AuthContext";
import Campaign from "@containers/Recruiter/Campaign/Campaign";
import Dashboard from "@containers/Admin/Dashboard/Dashboard";

interface TProtectedRoute {
  target?: string;
  children?: React.ReactNode;
}
interface TAuthProtectedRoute {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<TProtectedRoute> = ({ target, children }) => {
  const location = useLocation();
  const {
    isAuthenticated,
    hasTalentPackage,
    hasRecruiterPackage,
    hasEmployerPackage,
    isAdmin,
  } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }

  var isTargetTo: boolean = false;

  switch (target) {
    case "Talent":
      if (hasTalentPackage) {
        isTargetTo = true;
      }
      break;
    case "Recruiter":
      if (hasRecruiterPackage) {
        isTargetTo = true;
      }
      break;
    default:
      if (hasEmployerPackage) {
        isTargetTo = true;
      }
      break;
  }

  if (!isTargetTo) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const AuthProtectedRoute: React.FC<TAuthProtectedRoute> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export const HeppaiRoute = () => {
  return (
    <Routes>
      {/* HOME ROUTER */}
      <Route
        path="/"
        element={
          <Home isLanding={useLocation().pathname === "/" ? true : false} />
        }
      >
        <Route index element={<LandingPage />} />
        <Route path="recruiter" element={<RecruiterPage />} />
      </Route>

      {/* AUTHENTICATE ROUTER */}
      <Route
        path="/auth"
        element={
          <AuthProtectedRoute>
            <Auth />
          </AuthProtectedRoute>
        }
      >
        <Route path="login" element={<SignIn />} />
        <Route path="register" element={<SignUp />} />
      </Route>

      {/* TALENT ROUTER */}
      <Route
        path="/talent/panel"
        element={
          <ProtectedRoute target="Talent">
            <Talent />
          </ProtectedRoute>
        }
      >
        <Route index element={<JobHub />} />
      </Route>

      {/* RECRUITER ROUTER */}
      <Route
        path="/recruiter/panel"
        element={
          <ProtectedRoute target="Recruiter">
            <Recruiter />
          </ProtectedRoute>
        }
      >
        <Route index element={<TalentSourcing />} />
        <Route path="dashboard" element={<ComingSoon />} />
        <Route path="job" element={<ComingSoon />} />
        <Route path="setting" element={<ComingSoon />} />
        <Route path="campaign" element={<Campaign />} />
      </Route>

      {/* EMPLOYER ROUTER */}
      <Route element={<ProtectedRoute />}>
        <Route path="/employer" element={<Employer />} />
      </Route>

      {/* ADMIN ROUTER */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="permission" element={<ComingSoon />} />
        <Route path="server" element={<ComingSoon />} />
        <Route path="talent/client" element={<ComingSoon />} />
        <Route path="talent/plan" element={<ComingSoon />} />
        <Route path="talent/feature" element={<ComingSoon />} />
        <Route path="recruiter/client" element={<ComingSoon />} />
        <Route path="recruiter/plan" element={<ComingSoon />} />
        <Route path="recruiter/feature" element={<ComingSoon />} />
        <Route path="employer/client" element={<ComingSoon />} />
        <Route path="employer/plan" element={<ComingSoon />} />
        <Route path="employer/feature" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoute;
