import React from "react";
import addBtn from "@assets/img/add.svg";

interface TPositionFilter {
  keywords: any;
  handleCheckboxChange: (index: any, type: any) => void;
  handleAddKeyword: (e: any, type: string, name?: string) => void;
  showPositionInput: boolean;
  setShowPositionInput: (value: boolean) => void;
  tempPosition: string;
  setTempPosition: (value: string) => void;
}

const PositionFilter: React.FC<TPositionFilter> = ({
  keywords,
  handleCheckboxChange,
  handleAddKeyword,
  showPositionInput,
  setShowPositionInput,
  tempPosition,
  setTempPosition,
}) => {
  return (
    <div className="position-filter mb-2">
      <div className="filter-label">Positions</div>
      <div className="form-filter" id="positionCheckboxes">
        {keywords.positions.length > 0 &&
          keywords.positions.map((item: any, index: any) => {
            return (
              <div className="form-check-item" key={index}>
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.title}
                    checked={!!item.value}
                    onChange={() => handleCheckboxChange(index, "position")}
                  />
                  <label className="form-check-label">{item.title}</label>
                </div>
              </div>
            );
          })}
        {!showPositionInput && (
          <div className="form-add" onClick={() => setShowPositionInput(true)}>
            <img src={addBtn} />
            <span>Add</span>
          </div>
        )}
        <div className={`form-input ${showPositionInput ? `show` : ""}`}>
          <input
            type="text"
            className={`position-input ${showPositionInput ? `show` : ""}`}
            placeholder="Enter Position"
            value={tempPosition}
            onChange={(e) => setTempPosition(e.target.value)}
            onKeyDown={(e): void => handleAddKeyword(e, "position")}
          />
          <button
            className="btn-close small"
            onClick={() => setShowPositionInput(false)}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default PositionFilter;
