import { Menu } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "@assets/img/logo/logo-only-admin.svg";
import arrowRight from "@assets/img/arrow-right.svg";
import "./SideBar.scss";
import { LeftOutlined } from "@ant-design/icons";

interface TSideBar {
  sidebarCollapse: boolean;
  setSidebarCollapse: (type: boolean) => void;
}

const Sidebar: React.FC<TSideBar> = ({
  sidebarCollapse,
  setSidebarCollapse,
}) => {
  const navigate = useNavigate();
  const adminPath = {
    dashboard: "/admin",
    permissions: "/admin/permission",
    server: "/admin/server",
    talentClient: "/admin/talent/client",
    recruiterClient: "/admin/recruiter/client",
    employerClient: "/admin/employer/client",
    talentSubcripionPlan: "/admin/talent/plan",
    recruiterSubcripionPlan: "/admin/recruiter/plan",
    employerSubcripionPlan: "/admin/employer/plan",
    talentFeature: "/admin/talent/feature",
    recruiterFeature: "/admin/recruiter/feature",
    employerFeature: "/admin/employer/feature",
  };

  const items = [
    {
      key: adminPath.dashboard,
      icon: <i className="fa-solid fa-gauge-high nav-item-icon"></i>,
      label: "Dashboard",
    },
    {
      key: adminPath.permissions,
      icon: <i className="fa-solid fa-user-lock nav-item-icon"></i>,
      label: "Permission",
    },
    {
      key: adminPath.server,
      icon: <i className="fa-solid fa-server nav-item-icon"></i>,
      label: "Servers",
    },
    {
      key: "Talent",
      label: "Talent",
      icon: <i className="fa-solid fa-user-graduate nav-item-icon"></i>,
      children: [
        { key: adminPath.talentClient, label: "Client" },
        { key: adminPath.talentSubcripionPlan, label: "Subcription Plan" },
        { key: adminPath.talentFeature, label: "Feature" },
      ],
    },
    {
      key: "Recruiter",
      label: "Recruiter",
      icon: <i className="fa-solid fa-user nav-item-icon"></i>,
      children: [
        { key: adminPath.recruiterClient, label: "Client" },
        { key: adminPath.recruiterSubcripionPlan, label: "Subcription Plan" },
        { key: adminPath.recruiterFeature, label: "Feature" },
      ],
    },
    {
      key: "Employer",
      label: "Employer",
      icon: <i className="fa-solid fa-user-tie nav-item-icon"></i>,
      children: [
        { key: adminPath.employerClient, label: "Client" },
        { key: adminPath.employerSubcripionPlan, label: "Subcription Plan" },
        { key: adminPath.employerFeature, label: "Feature" },
      ],
    },
  ];

  return (
    <div className={`Admin-Sidebar ${sidebarCollapse ? "collapsed" : ""}`}>
      <div className="Admin-Sidebar-container d-flex flex-column flex-shrink-0 p-3 h-100">
        <a href="/" className="sidebar-logo mb-3 mb-md-0">
          <img src={logo} width={50} alt="HEPPAI"/>
          <span className="sidebar-title">ADMIN</span>
        </a>
        <button
          className="collapse-btn"
          onClick={() => setSidebarCollapse(!sidebarCollapse)}
        >
          <LeftOutlined color="#132050" rotate={sidebarCollapse ? 180 : 0} />
        </button>
        <div className="admin-menu pt-3 mt-3 mb-auto">
          <Menu
            defaultSelectedKeys={[adminPath.dashboard]}
            mode="inline"
            items={items}
            onClick={(item: any) => {
              navigate(item.key);
            }}
            inlineCollapsed={sidebarCollapse}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
