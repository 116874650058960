import React from "react";
import "./Dashboard.scss";

const Dashboard: React.FC = () => {
  return (
    <div className="AdminDashboard">
      <h4 className="page-title">Dashboard</h4>
      <div className="row g-3">
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="summary-item yearly-earning">
            <div className="d-flex align-items-center gap-3">
              <div className="flex-shrink-0">
                <div className="icon-wrap">
                  <i className="fa-solid fa-wallet"></i>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="summary-title">Yearly Earning</div>
                <div className="summary-value">210.560.000 VNĐ</div>
                <div className="summary-growth">
                  <span className="increase">
                    <i className="fa-solid fa-arrow-up-long"></i> 35%
                  </span>
                  since last year
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="summary-item monthly-earning">
            <div className="d-flex align-items-center gap-3">
              <div className="flex-shrink-0">
                <div className="icon-wrap">
                  <i className="fa-solid fa-receipt"></i>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="summary-title">Monthly Earning</div>
                <div className="summary-value">74.542.000 VNĐ</div>
                <div className="summary-growth">
                  <span className="increase">
                    <i className="fa-solid fa-arrow-up-long"></i> 46%
                  </span>
                  since last month
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="summary-item new-client">
            <div className="d-flex align-items-center gap-3">
              <div className="flex-shrink-0">
                <div className="icon-wrap">
                  <i className="fa-solid fa-user-tie"></i>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="summary-title">New clients (monthly)</div>
                <div className="summary-value">216</div>
                <div className="summary-growth">
                  <span className="decrease">
                    <i className="fa-solid fa-arrow-down-long"></i> 8%
                  </span>
                  since last month
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6">
          <div className="summary-item third-party">
            <div className="d-flex align-items-center gap-3">
              <div className="flex-shrink-0">
                <div className="icon-wrap">
                  <i className="fa-solid fa-cube"></i>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="summary-title">Third party fees</div>
                <div className="summary-value">5.500.000 VNĐ</div>
                <div className="summary-growth">
                  <span className="increase">
                    <i className="fa-solid fa-arrow-up-long"></i> 12%
                  </span>
                  since last month
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
