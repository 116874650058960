import React from "react";
import './CampaignModal.scss';
import Modal from "antd/es/modal/Modal";
import Input from "antd/es/input/Input";

interface TCampaignModal {
  isOpen: any;
  submit: () => void;
  cancel: () => void;
}

const CampaignModal: React.FC<TCampaignModal> = ({ isOpen, submit, cancel }) => {
  return (
    <Modal title="CAMPAIGN" open={isOpen} onOk={submit} onCancel={cancel}>
      <Input />
    </Modal>
  )
}

export default CampaignModal;

