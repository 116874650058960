import React from "react";
import './Header.scss'
import logo from '@assets/img/logo/logo-only.svg';
import glob from '@assets/img/globe.svg';
import VietnameseFlag from '@assets/img/flags/vietnamese.png';
import EnglishFlag from '@assets/img/flags/english.png';


const Header: React.FC = () => {
    return (
        <nav className="navbar navbar-expand-lg talent-header bg-white border-bottom" style={{ width: '100vw' }}>
            <div className="container-fluid px-5 py-1">
                <a className="navbar-brand" href="/">
                    <img src={logo} height="35" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0 text-center gap-4 header-menu">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#" >Find Jobs</a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Profile & CV</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Gross/Net</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Companies</a>
                        </li>
                    </ul>
                    <div className="d-flex gap-3 ms-auto">
                        <div className="language-group">
                            <div className="dropdown">
                                <button className="border-0 p-2 bg-transparent dropdown-toggle dropdown-language" type="button" data-bs-toggle="dropdown" style={{ borderRadius: '10px' }}>
                                    <img src={glob} width="24" />
                                    <span id="language_src_text">EN</span>
                                </button>
                                <ul className="dropdown-menu dropdown-language-box">
                                    <li className="mb-1">
                                        <a className="dropdown-item d-flex align-items-center gap-2">
                                            <img src={VietnameseFlag} width="20" />
                                            <span key="Vietnamese">VN</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item d-flex align-items-center gap-2">
                                            <img src={EnglishFlag} width="20" />
                                            <span key="English">EN</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="authenticate-group d-flex align-items-center gap-2">
                            <a id="login-btn" href="/login">Login</a>
                            <button id="register-btn" key="Start_free_trial">Register</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;