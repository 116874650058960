import React from "react";
import "./BgCover.scss";

const BgCover: React.FC = () => {
  return (
    <div className="BgCover hero-static col-lg-7 d-none d-lg-flex flex-column justify-content-end">
      <div className="p-3 p-xl-4 d-xl-flex justify-content-between align-items-center fs-sm">
        <p className="fw-medium text-white-50 mb-0">
          <strong>HeppAI 0.0.1</strong>
        </p>
        <ul className="list list-inline mb-0 py-2">
          <li className="list-inline-item me-4">
            <a className="text-white-75 fw-medium" href="#">
              Legal
            </a>
          </li>
          <li className="list-inline-item me-4">
            <a className="text-white-75 fw-medium" href="#">
              Contact
            </a>
          </li>
          <li className="list-inline-item me-4">
            <a className="text-white-75 fw-medium" href="#">
              Terms
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BgCover;
