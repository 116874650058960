import React from "react";
import addBtn from "@assets/img/add.svg";

interface TOtherFilter {
  keywords: any;
  handleCheckboxChange: (index: any, type: any) => void;
  handleAddKeyword: (e: any, type: string, name?: string) => void;
  showOtherInput: boolean;
  setShowOtherInput: (value: boolean) => void;
  tempOther: string;
  setTempOther: (value: string) => void;
}

const OtherFilter: React.FC<TOtherFilter> = ({
  keywords,
  handleCheckboxChange,
  handleAddKeyword,
  showOtherInput,
  setShowOtherInput,
  tempOther,
  setTempOther,
}) => {
  return (
    <div className="other-filter mb-2">
      <div className="filter-label">Other</div>
      <div className="form-filter" id="criteriaCheckboxes">
        {keywords.others.length > 0 &&
          keywords.others.map((item: any, index: any) => {
            return (
              <div className="form-check-item" key={index}>
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.title}
                    checked={!!item.value}
                    onChange={() => handleCheckboxChange(index, "other")}
                  />
                  <label className="form-check-label">{item.title}</label>
                </div>
              </div>
            );
          })}
        {!showOtherInput && (
          <div className="form-add" onClick={() => setShowOtherInput(true)}>
            <img src={addBtn} />
            <span>Add</span>
          </div>
        )}
        <div className={`form-input ${showOtherInput ? `show` : ""}`}>
          <input
            type="text"
            className={`other-input ${showOtherInput ? `show` : ""}`}
            placeholder="Enter Criteria"
            value={tempOther}
            onChange={(e) => setTempOther(e.target.value)}
            onKeyDown={(e): void => handleAddKeyword(e, "other")}
          />
          <button
            className="btn-close"
            onClick={() => setShowOtherInput(false)}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default OtherFilter;
