import { Empty, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./CandidateDetail.scss";
import linkedImg from "@assets/img/logo/Linkedin-color.svg";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import RadarChart from "@components/Recruiter/RadarChart/RadarChart";
interface TCandidateDetail {
  isOpen: any;
  cancel: () => void;
  candidateProfile: any;
  candidateEvaluate: any;
  selectedDetail: string;
  setSelectedDetail: (value: string) => void;
}
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const CandidateDetail: React.FC<TCandidateDetail> = ({
  isOpen,
  cancel,
  candidateProfile,
  candidateEvaluate,
  setSelectedDetail,
  selectedDetail,
}) => {
  const [datasets, setDatasets] = useState([0, 0, 0, 0, 0]);
  const [selectedProfile, setSelectedProfile] = useState<any>("");
  const [selectedEvaluate, setSelectedEvaluate] = useState<any>("");

  useEffect(() => {
    if (selectedDetail !== "") {
      candidateEvaluate.forEach((item: any) => {
        if (item.slug === selectedDetail) {
          const newDatasets = [
            item.overall,
            item.skills,
            item.education,
            item.languages,
            item.experience,
          ];
          setDatasets(newDatasets);
        }
      });
      const newSelectedProfile = candidateProfile.find(
        (item: any) => item.slug === selectedDetail
      );
      const newSelectedEvaluate = candidateEvaluate.find(
        (item: any) => item.slug === selectedDetail
      );
      setSelectedProfile(newSelectedProfile);
      setSelectedEvaluate(newSelectedEvaluate);
    }
  }, [selectedDetail, candidateEvaluate, candidateProfile]);

  useEffect(() => {
    if (selectedEvaluate !== "") {
      const formated: string = formatText(selectedEvaluate.feed_back);
      document.querySelector(".feedback-section")!.innerHTML = formated;
    }
  }, [selectedEvaluate]);

  const formatText = (text: string): any => {
    let formattedText = text
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace("```", "");
    formattedText = formattedText.replace(/```/g, "");
    formattedText = formattedText.replace(/\* /g, "<br />");
    return formattedText;
  };

  return (
    <Modal
      title={"Candidate Detail"}
      className="CandidateDetail"
      open={isOpen}
      onCancel={cancel}
      width={"90vw"}
      footer={false}
    >
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="candidate-list">
            {candidateProfile.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`candidate-item px-2 ${
                    item.slug === selectedDetail ? "active" : ""
                  } d-flex gap-2 align-items-center`}
                  onClick={() => setSelectedDetail(item.slug)}
                >
                  <div className="flex-shrink-0">
                    <img
                      src={item.details.avatar}
                      className="img-fluid rounded-circle"
                      width={40}
                      alt={item?.detail?.name}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <div className="candidate-name d-flex align-items-center gap-2">
                      {item.details.name}
                      <a
                        href={item.details.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={linkedImg}
                          width={15}
                          alt={item.details.url}
                        />
                      </a>
                    </div>
                    <div className="candidate-position">
                      {item.details.position}
                    </div>
                  </div>
                  {item.slug === selectedDetail && (
                    <div className="flex-grow-2 ms-auto">
                      <i className="icon-selected fa-solid fa-check"></i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xl-5 col-lg-4">
          <div className="candidate-profile">
            <div className="profile-avatar-group w-100">
              <div className="profile-avatar">
                <img
                  className="rounded-circle"
                  src={selectedProfile?.details?.avatar}
                  width="150"
                  alt={selectedProfile?.details?.name}
                />
                <div className="profile-total-score">
                  {parseInt(selectedEvaluate?.overall) / 10}/10
                </div>
              </div>
            </div>
            <div className="profile-name text-center mt-2">
              {selectedProfile?.details?.name}
            </div>
            <div className="profile-position text-center">
              {selectedProfile?.details?.postion}
            </div>

            <div className="profile-detail">
              <div className="profile-introduce mt-3">
                <div className="profile-title">About</div>
                {selectedProfile?.details?.crawl_profile_about === null ||
                selectedProfile?.details?.crawl_profile_about === undefined ? (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : (
                  selectedProfile?.details?.crawl_profile_about[0].about
                )}
              </div>
              <div className="profile-experience mt-3">
                <div className="profile-title">Work Experiences</div>
                {selectedProfile?.details?.crawl_profile_exps?.length > 0 ? (
                  selectedProfile?.details?.crawl_profile_exps.map(
                    (item: any, index: any) => {
                      return (
                        <div className="exp-item" key={index}>
                          {item.time_period}: {item.job_title} at{" "}
                          {item.company_name}
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
              <div className="profile-education mt-3">
                <div className="profile-title">Education</div>
                {selectedProfile?.details?.crawl_profile_educations?.length >
                0 ? (
                  selectedProfile?.details?.crawl_profile_educations.map(
                    (item: any, index: any) => {
                      return (
                        <div className="edu-item" key={index}>
                          {item.time_period}: {item.degree_type} in{" "}
                          {item.school_name}
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="d-flex w-100 align-items-center justify-content-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
              <div className="profile-skills mt-3">
                <div className="profile-title">Skills</div>
                <div className="profile-skills-wrap d-flex flex-wrap gap-1">
                  {selectedProfile?.details?.crawl_profile_skills?.length >
                  0 ? (
                    selectedProfile?.details?.crawl_profile_skills.map(
                      (item: any, index: any) => {
                        return (
                          <div className="skills-item" key={index}>
                            {item.skill_name}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="d-flex w-100 align-items-center justify-content-center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
              </div>
              <div className="profile-languages mt-3">
                <div className="profile-title">Languages</div>
                <div className="profile-languages-wrap d-flex flex-wrap gap-1">
                  {selectedProfile?.details?.crawl_profile_languages?.length >
                  0 ? (
                    selectedProfile?.details?.crawl_profile_languages.map(
                      (item: any, index: any) => {
                        return (
                          <div className="language-item" key={index}>
                            {item.language}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="d-flex w-100 align-items-center justify-content-center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4">
          <div className="radar-section d-flex justify-content-center">
            <RadarChart datasets={datasets} />
          </div>
          <div className="p-2 h-50">
            <div className="feedback-section"></div>
          </div>
          {/* <div className="d-flex w-100 h-50 align-items-center justify-content-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default CandidateDetail;
