import { useEffect, useState } from "react";
import { Button, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import VerifyModal from "@components/Auth/VerifySection/VerifySection";
import AuthenticateApi from "services/AuthenticateApi";
import imgLogo from "@assets/img/logo/logo-only.svg";
import Helpers from "@utils/Helpers";
import talent from "@assets/img/auth/talent.svg";
import recruiter from "@assets/img/auth/recruiter.svg";
import employer from "@assets/img/auth/employer.svg";
import "./SignUp.scss";

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isVerifyVisible, setIsVerifyVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accountId, setAccountId] = useState("");
  const [defaultView, setDefaultView] = useState("Recruiter");
  const navigate = useNavigate();
  const notification = Helpers.notification;

  const generateUniqueId = () => {
    const timestamp = Date.now().toString();
    const uniqueId = `${firstName}${lastName}${timestamp}`;
    setAccountId(uniqueId);
  };

  useEffect(() => {
    if (firstName != "" && lastName != "") {
      generateUniqueId();
    }
  }, [firstName, lastName]);

  const validateForm = (form: any) => {
    var check = true;
    var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (form.firstName == "" || form.lastName == "") {
      notification.error("First name and Last name is required");
      check = false;
    }

    if (!emailPattern.test(form.email)) {
      notification.error("Email is invalid");
      check = false;
    }

    if (form.phone.length < 8 || form.phone.length > 16) {
      notification.error("Phone is invalid");
      check = false;
    }

    if (form.password !== form.confirmPassword) {
      notification.error("Confirm password does not match");
      check = false;
    }

    if (form.password.length < 8 || form.password.length > 16) {
      notification.error("Password must have at least 8 to 16 characters");
      check = false;
    }

    return check;
  };

  const register = async () => {
    setLoading(true);
    const check = validateForm({
      email,
      phone,
      password,
      confirmPassword,
      firstName,
      lastName,
    });
    if (!check) {
      setLoading(false);
      return;
    }

    const payload = {
      accountId: accountId,
      fullName: firstName + " " + lastName,
      phone: phone,
      email: email,
      password: password,
      defaultView: defaultView,
    };

    try {
      const response = await AuthenticateApi.Register(payload);
      if (response.status == 200) {
        setLoading(false);
        setIsVerifyVisible(true);
      }
    } catch (responseError: any) {
      setLoading(false);
      responseError.status == 401
        ? notification.error("Your email is already exists")
        : notification.error("Something wrong in server");
    }
  };

  const verifyOTP = async (otp: string) => {
    setLoading(true);
    const payload = {
      email: email,
      otp: otp,
    };
    try {
      const response = await AuthenticateApi.VerifyOTP(payload);
      if (response.status == 200) {
        notification.success("Register successfully");
        navigate("/auth/login");
      }
    } catch (responseError: any) {
      setLoading(false);
      responseError.status == 401
        ? notification.error("Invalid OTP")
        : notification.error("Something wrong in server");
    }
  };
  return (
    <>
      <div className="SignUp hero-static col-lg-5 d-flex flex-column align-items-center bg-body-light">
        {loading && (
          <div id="overlay-loader">
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 48, color: "#fff" }} spin />
              }
            />
          </div>
        )}
        <div className="p-4 w-100 flex-grow-1 d-flex align-items-center">
          <div className="w-100">
            <div className="text-center mb-5">
              <img src={imgLogo} width={70} />
              <h1 className="fw-bold m-0">
                Welcome to{" "}
                <span>
                  HEPP<span>AI</span>
                </span>
              </h1>
            </div>
            {!isVerifyVisible ? (
              <div className="row g-0 justify-content-center">
                <div className="col-xl-11">
                  <div className="mb-4">
                    <div className="row">
                      <div className="col-4">
                        <div className="form-register-title">First name</div>
                        <Input
                          value={firstName}
                          className="form-register"
                          onChange={(e) => {
                            if (e.target.value == "") {
                              setAccountId("");
                            }
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <div className="form-register-title">Last name</div>
                        <Input
                          value={lastName}
                          className="form-register"
                          onChange={(e) => {
                            if (e.target.value == "") {
                              setAccountId("");
                            }
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <div className="form-register-title">Account ID</div>
                        <Input
                          value={accountId}
                          className="form-register"
                          onChange={(e) => setAccountId(e.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-register-title">Email</div>
                    <Input
                      value={email}
                      className="form-register"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="form-register-title">Phone</div>
                    <Input
                      value={phone}
                      className="form-register"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-register-title">Password</div>
                        <Input
                          type="password"
                          value={password}
                          className="form-register"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="col-6">
                        <div className="form-register-title">
                          Confirm Password
                        </div>
                        <Input
                          type="password"
                          value={confirmPassword}
                          className="form-register"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="default-view-section mt-4">
                    <div className="text-center">Choose you default view</div>
                    <div className="default-view-group">
                      <div
                        className={`default-view-item ${
                          defaultView == "Recruiter" ? "active" : ""
                        }`}
                        onClick={() => setDefaultView("Recruiter")}
                      >
                        <img src={recruiter} width={80} />
                        <div className="default-view-title">Recruiter</div>
                      </div>
                      <div
                        className={`default-view-item ${
                          defaultView == "Talent" ? "active" : ""
                        }`}
                        onClick={() => setDefaultView("Talent")}
                      >
                        <img src={talent} width={80} />
                        <div className="default-view-title">Talent</div>
                      </div>
                      <div
                        className={`default-view-item ${
                          defaultView == "Employer" ? "active" : ""
                        }`}
                        onClick={() => setDefaultView("Employer")}
                      >
                        <img src={employer} width={70} />
                        <div className="default-view-title">Employer</div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-group text-center mt-3">
                    <div className="term-and-conditions">
                      By clicking Let’s Start, you agree to the HEPPAI{" "}
                      <span>Terms & Conditions</span>
                    </div>
                    <Button className="signup-btn" onClick={register}>
                      Let's Start
                    </Button>
                    <div className="back-signin">
                      Already had an account?{" "}
                      <Link to="/auth/login">Sign In</Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <VerifyModal
                email={email}
                isVerifyOpen={isVerifyVisible}
                verifyOTP={verifyOTP}
              />
            )}
          </div>
        </div>
        <div className="px-4 py-2 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">
          <p className="fw-medium text-black-50 py-2 mb-0">
            <strong>HEPPAI 0.0.1</strong> &copy;{" "}
            <span data-toggle="year-copy"></span>
          </p>
          <ul className="list list-inline py-2 mb-0">
            <li className="list-inline-item">
              <a className="text-muted fw-medium" href="#">
                Legal
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted fw-medium" href="#">
                Contact
              </a>
            </li>
            <li className="list-inline-item">
              <a className="text-muted fw-medium" href="#">
                Terms
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SignUp;
