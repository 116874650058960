import React from "react";

const ComingSoon: React.FC = () => {
  const style = {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "15px",
  };
  return (
    <>
      <h4 className="page-title">{'Coming Soon'}</h4>
      <div style={style}>
        <img src="https://static.vecteezy.com/system/resources/thumbnails/019/641/473/small_2x/coming-soon-3d-label-promotion-banner-png.png" />
      </div>
    </>
  );
};

export default ComingSoon;
