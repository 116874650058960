import React, { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(PointElement, LinearScale, Title, Tooltip, Legend);
interface TScatterChart {
  linkedinEvaluateData: any;
  linkedinProfileData: any;
}

const ScatterChart: React.FC<TScatterChart> = ({
  linkedinProfileData,
  linkedinEvaluateData,
}) => {
  const [datasets, setDatasets] = useState({ datasets: [] });
  const [options, setOptions] = useState<any>({
    responsive: true,
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Experience",
        },
        min: 0,
      },
      y: {
        title: {
          display: true,
          text: "Skill",
        },
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });
  const handleChangeSelection = (event: any) => {
    const selected = event.target.value;
    const newDataset = [...datasets.datasets];
    const newOptions = {...options}
    newDataset.map((item: any) => {
      switch (selected) {
        case "overall-exp":
          item.data[0].y = item.data[0].overall;
          break;
        case "lang-exp":
          item.data[0].y = item.data[0].language;
          break;
        case "edu-exp":
          item.data[0].y = item.data[0].education;
          break;
        default:
          item.data[0].y = item.data[0].skill;
          break;
      }
    });
    switch (selected) {
      case "overall-exp":
        newOptions.scales.y.title.text = "Overall";
        break;
      case "lang-exp":
        newOptions.scales.y.title.text = "Language";
        break;
      case "edu-exp":
        newOptions.scales.y.title.text = "Education";
        break;
      default:
        newOptions.scales.y.title.text = "Skill";
        break;
    }
    setDatasets({ ...datasets, datasets: newDataset });
    setOptions(newOptions)
  };

  useEffect(() => {
    if (linkedinProfileData.length > 0) {
      const datasetsTemp: any = [...datasets.datasets];
      const lastItem = linkedinProfileData[linkedinProfileData.length - 1];
      const chartItem = {
        label: lastItem.details.name,
        data: [{ x: 0, y: 0 }],
        backgroundColor: "rgba(90, 198, 198, 1)",
        slug: lastItem.slug,
      };
      datasetsTemp.push(chartItem);
      setDatasets({ ...datasets, datasets: datasetsTemp });
    }
  }, [linkedinProfileData]);

  useEffect(() => {
    if (linkedinEvaluateData.length > 0 && datasets.datasets.length > 0) {
      const lastItem = linkedinEvaluateData[linkedinEvaluateData.length - 1];
      const newDatasets: any = [...datasets.datasets];
      newDatasets.map((item: any) => {
        if (item.slug == lastItem.slug) {
          const scorePoint = [
            {
              x: parseInt(lastItem.skills),
              y: parseInt(lastItem.experience),
              skill: parseInt(lastItem.skills),
              experience: parseInt(lastItem.experience),
              language: parseInt(lastItem.languages),
              overall: parseInt(lastItem.overall),
              education: parseInt(lastItem.education),
            },
          ];
          item.data = scorePoint;
        }
      });

      setDatasets({ datasets: newDatasets });
    }
  }, [linkedinEvaluateData]);

  return (
    <div className="card-data flex-fill" style={{ maxHeight: "29.9vh" }}>
      <select
        className="form-select form-select-sm text-sm"
        defaultValue={"skill-exp"}
        onChange={handleChangeSelection}
      >
        <option className="text-sm" value="skill-exp">
          Skill - Experience
        </option>
        <option className="text-sm" value="overall-exp">
          Overall - Experience
        </option>
        <option className="text-sm" value="lang-exp">
          Language - Experience
        </option>
        <option className="text-sm" value="edu-exp">
          Education - Experience
        </option>
      </select>
      <div className="d-flex h-100 flex-column justify-content-center mt-3">
        <Scatter data={datasets} options={options} />
      </div>
    </div>
  );
};

export default ScatterChart;
