// src/api/userApi.js
import { apiClient } from './apiConfig';

interface TloginPayload {
    email: string,
    password: string
}

interface TRegisterPayload {
    email: string,
    password: string,
    phone: string,
    fullName: string
}

interface TVerifyOTPPayload {
    email: string,
    otp: string
}

const businessLogicApi = {
    Register: (data: TRegisterPayload) => {
        return apiClient.post('/register/', data);
    },
    VerifyOTP: (data: TVerifyOTPPayload) => {
        return apiClient.post('/verify/otp/', data)
    },
    Login: (data: TloginPayload) => {
        return apiClient.post('/login/', data)
    },
    GoogleLogin: (credential: any) => {
        return apiClient.post('/google/login/', credential)
    }
};

export default businessLogicApi;
