import React from "react";
import "./SideBar.scss";
import { Menu, Popover } from "antd";
import logo from "@assets/img/logo/logo-only-white.svg";
import arrowLeft from "@assets/img/arrow-left.svg";
import arrowRight from "@assets/img/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

interface TSideBar {
  sidebarCollapse: boolean;
  setSidebarCollapse: (type: boolean) => void;
}

const SideBar: React.FC<TSideBar> = ({
  sidebarCollapse,
  setSidebarCollapse,
}) => {
  const navigate = useNavigate();

  const recruiterPath = {
    dashboard: "/recruiter/panel/dashboard",
    talentSourcing: "/recruiter/panel",
    campaignList: "/recruiter/panel/campaign",
    job: "/recruiter/panel/job",
    setting: "/recruiter/panel/setting",
  };

  const items = [
    {
      key: recruiterPath.dashboard,
      icon: <i className="fa-solid fa-chart-line nav-item-icon"></i>,
      label: "Dashboard",
    },
    {
      key: recruiterPath.talentSourcing,
      icon: <i className="fa-solid fa-person-shelter nav-item-icon"></i>,
      label: "Talent Sourcing",
    },
    {
      key: recruiterPath.campaignList,
      icon: <i className="fa-solid fa-bars-staggered nav-item-icon"></i>,
      label: "Campaign",
    },
    {
      key: recruiterPath.job,
      icon: <i className="fa-solid fa-briefcase nav-item-icon"></i>,
      label: "Job Hub",
    },
    {
      key: recruiterPath.setting,
      icon: <i className="fa-solid fa-gear nav-item-icon"></i>,
      label: "Settings",
    },
  ];

  return (
    <div className={`RecruiterSidebar ${sidebarCollapse ? "collapsed" : ""}`}>
      <div className="RecruiterSidebar-container d-flex flex-column p-3 h-100">
        <a href="/" className="sidebar-logo mb-3">
          <img src={logo} width={50} />
          <span className="sidebar-title">HEPPAI</span>
        </a>
        <button
          className="collapse-btn"
          onClick={() => setSidebarCollapse(!sidebarCollapse)}
        >
          <LeftOutlined color="#132050" rotate={sidebarCollapse ? 180 : 0} />
        </button>
        <div className="input-container">
          <span className="prefix">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
          <input
            type="text"
            className="form-control-search"
            placeholder="Search"
          />
        </div>
        <div className="recruiter-menu mt-3 mb-auto">
          <Menu
            defaultSelectedKeys={[recruiterPath.talentSourcing]}
            mode="inline"
            items={items}
            onClick={(item: any) => {
              navigate(item.key);
            }}
            inlineCollapsed={sidebarCollapse}
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
