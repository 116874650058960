import React from "react";
import blackLogo from "@assets/img/logo/as_black_logo.png";
import linkedIn from "@assets/img/logo/Linkedin.svg";
import Facebook from "@assets/img/logo/Facebook.svg";
import "./Footer.scss";

const Footer: React.FC<any> = ({ isLanding = false }) => {
  return (
    <div className={`footer-section ${isLanding ? 'absolute' : ''}`}>
      <div className="row gy-3">
        <div className="col-lg-5 col-md-12 footer-branch-group">
          <div className="branch-container d-flex align-items-center gap-1">
            <img src={blackLogo} alt="" width={100} />
            <div className="branch-slogan d-flex flex-column">
              <span>Streamline Sourcing</span>
              <span>Empowering Growth</span>
              <span>Unlock Full Potentials</span>
            </div>
          </div>
          <div className="branch-social mt-5">
            <div className="d-flex gap-3">
              <img src={linkedIn} alt="" />
              <img src={Facebook} alt="" />
            </div>
          </div>
          <div className="branch-contact mt-2 ps-1">
            <div className="fw-bold">Contact us</div>
            <div className="branch-contact-support">
              support@automate-solutions.com
            </div>
            <div className="branch-contact-address">
              Floor 3, 21 Phan Ke Binh St, Da Kao, District 1, HCMC, Vietnam
            </div>
          </div>
          <div className="branch-terms mt-4">
            <span className="branch-copyright">
              © AutoMATE Solutions 2024. All Rights Reserved
            </span>
            <div className="d-flex flex-wrap branch-resources gap-2">
              <a href="">Privacy Policy</a>
              <a href="">Cookie Policy</a>
              <a href="">Terms Of Use</a>
              <a href="">Security</a>
              <a href="">Accessibility</a>
              <a href="">Helpdesk</a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 footer-services-group">
          <div className="d-flex flex-wrap w-100 gap-4">
            <div className="services-group flex-fill">
              <div className="group-title">Services</div>
              <div className="group-item">Talent Development</div>
              <div className="group-item">Recruitment Automation</div>
              <div className="group-item">Professional Advancement</div>
            </div>
            <div className="whyheppai-group flex-fill">
              <div className="group-title">Why HEPPAI</div>
              <div className="group-item">Customers</div>
              <div className="group-item">Partners</div>
              <div className="group-item">Affifiates</div>
            </div>
            <div className="company-group flex-fill">
              <div className="group-title">Company</div>
              <div className="group-item">About us</div>
              <div className="group-item">Careers</div>
              <div className="group-item">News</div>
            </div>
            <div className="faq-group flex-fill">
              <div className="group-title">FAQs</div>
              <div className="group-item">Help Center</div>
              <div className="group-item">Accessibility</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
