import React, { useEffect, useState } from "react";
import logo from "@assets/img/logo/AS_logo.png";
import glob from "@assets/img/globe.svg";
import VietnameseFlag from "@assets/img/flags/vietnamese.png";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import EnglishFlag from "@assets/img/flags/english.png";
import "./Header.scss";
import { Button, Drawer, Dropdown, Menu, Space } from "antd";
import type { MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import talent from "@assets/img/talent.png";
import recruiter from "@assets/img/recruiter.png";
import employer from "@assets/img/employer.png";
import Helpers from "@utils/Helpers";
import { useAuth } from "AuthContext";

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawlerVisible, setDrawlerVisible] = useState(false);
  const isLoggedIn = Helpers.isLoggedIn();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link to={"/recruiter"} className="p-1 text-decoration-none">
          <div className="d-flex align-items-start gap-3">
            <div className="flex-shrink-0">
              <img src={recruiter} width={50} />
            </div>
            <div className="flex-grow-1">
              <div className="menu-item">Recruiter</div>
              <div className="menu-des">
                Provides a comprehensive suite of services tailored for
                recruiters, focusing on enhancing the efficiency and
                effectiveness of the hiring process.
              </div>
            </div>
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={"/talent/panel"} className="p-1 text-decoration-none">
          <div className="d-flex align-items-start gap-3">
            <div className="flex-shrink-0">
              <img src={talent} width={50} />
            </div>
            <div className="flex-grow-1">
              <div className="menu-item">Talent</div>
              <div className="menu-des">
                Provides a comprehensive suite of services tailored for
                recruiters, focusing on enhancing the efficiency and
                effectiveness of the hiring process.
              </div>
            </div>
          </div>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={"/employer"} className="p-1 text-decoration-none">
          <div className="d-flex align-items-start gap-3">
            <div className="flex-shrink-0">
              <img src={employer} width={50} />
            </div>
            <div className="flex-grow-1">
              <div className="menu-item">Employer</div>
              <div className="menu-des">
                Offers a comprehensive suit of services tailored to enhance
                employer capabilities in several key areas.
              </div>
            </div>
          </div>
        </Link>
      ),
    },
  ];

  const menuMobileItems = [
    {
      key: "/",
      label: "HEPPAI",
    },
    {
      key: "Branches",
      label: "Branches",
      children: [
        { key: "/recruiter", label: "Recruiter" },
        { key: "#", label: "Talent" },
        { key: "#", label: "Employer" },
      ],
    },
    {
      key: "/recruiter/panel",
      label: "Tool",
    },
    {
      key: "/auth/login",
      label: "Login",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logOut = () => {
    Helpers.clearToken();
    Helpers.removeUserInfo();
    logout();
    navigate("/auth/login");
  };

  const showDrawer = () => {
    setDrawlerVisible(true);
  };

  const onClose = () => {
    setDrawlerVisible(false);
  };

  return (
    <nav
      className={`navbar navbar-expand-lg heppai-header ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <div className="container-fluid header-container">
        <a
          className="navbar-brand"
          href="https://automate-solutions.net"
          target="_blank"
        >
          <img src={logo} height="45" alt="" />
        </a>
        <Button
          className="btn-menu-mobile"
          onClick={showDrawer}
          icon={<MenuOutlined />}
        ></Button>
        <Drawer
          title={<i className="fa-solid fa-bars"></i>}
          placement="right"
          onClose={onClose}
          open={drawlerVisible}
        >
          <Menu
            mode="inline"
            items={menuMobileItems}
            onClick={(item: any) => {
              navigate(item.key);
            }}
          />
        </Drawer>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex justify-content-center menu-group">
            <ul className="navbar-nav mb-2 mb-lg-0 text-center gap-5 header-menu w-75 justify-content-center">
              <li className="nav-item me-5">
                <Link className="nav-link active" to={"/"}>
                  HEPPAI
                </Link>
              </li>
              <li className="nav-item me-5">
                <Dropdown menu={{ items }}>
                  <a className="nav-link" onClick={(e) => e.preventDefault()}>
                    <Space>
                      Branches
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </li>

              <li className="nav-item me-5">
                <Link className="nav-link" to={"/recruiter/panel"}>
                  Tools
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex gap-3 ms-auto">
            <div className="language-group">
              <div className="dropdown">
                <button
                  className="border-0 p-2 bg-transparent dropdown-toggle dropdown-language"
                  type="button"
                  data-bs-toggle="dropdown"
                  style={{ borderRadius: "10px" }}
                >
                  <img src={glob} width="24" />
                  <span id="language_src_text">EN</span>
                </button>
                <ul className="dropdown-menu dropdown-language-box">
                  <li className="mb-1">
                    <a className="dropdown-item d-flex align-items-center gap-2">
                      <img src={VietnameseFlag} width="20" />
                      <span key="Vietnamese"></span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item d-flex align-items-center gap-2">
                      <img src={EnglishFlag} width="20" />
                      <span key="English"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {isLoggedIn ? (
              <a className="login-btn" onClick={logOut}>
                Logout
              </a>
            ) : (
              <Link to="/auth/login" className="login-btn">
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
