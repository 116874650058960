import React from "react";
import "./Header.scss";
import { Badge, Divider, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Header: React.FC = () => {
  return (
    <div className="AdminHeader px-0 bg-white">
      <div className="container-fluid h-100 header-group">
        <div className="menu-header">
          <div className="user-group">
            <div className="d-flex gap-2 align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar-group">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMl1uN124bLwPkEt7VC1HxLtsBHEyE5vHITA&s"
                    }
                    width={50}
                    className="rounded-circle"
                    alt="flag"
                  />
                  <div className="user-status"></div>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="user-name">Mark Zuckerberg</div>
                <div className="user-position">Facebook Osin</div>
              </div>
            </div>
            <Divider className="divider" type="vertical" />
          </div>
          <div className="calendar-group">
            <i className="fa-solid fa-calendar-days"></i>
            <span>View Calendar</span>
          </div>
          <div className="search-group">
            <Input className="search-input" placeholder="Search" />
            <SearchOutlined className="search-icon" />
          </div>
          <div className="action-group">
            <div className="notification">
              <i className="fa-regular fa-bell"></i>
            </div>
            <div className="inbox">
              <i className="fa-regular fa-comment-dots"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
