import React from "react";
import "./Header.scss";
import englishFlag from "@assets/img/flags/english.png";
import { Link } from "react-router-dom";
import { Badge, Divider } from "antd";

const Header: React.FC = () => {
  return (
    <div className="RecruiterHeader px-0 bg-white">
      <div className="container-fluid h-100 header-group">
        <div className="menu-header">
          <Link to="/">Home</Link>
          <Link to="/">Brances</Link>
          <Link to="/">About Us</Link>
          <Link to="/">News</Link>
          <Link to="/">FAQs</Link>
        </div>
        <Divider className="divider" type="vertical" />
        <div className="user-control-header">
          <img className="img-fluid rounded-3" width={30} src={englishFlag} />
          <Badge count={5} size="small">
            <i className="fa-regular fa-bell"></i>
          </Badge>
          <Badge count={8} size="small">
            <i className="fa-regular fa-message"></i>
          </Badge>
          <img
            src={
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMl1uN124bLwPkEt7VC1HxLtsBHEyE5vHITA&s"
            }
            width={30}
            className="rounded-3"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
