import React from "react";
import Lottie from "lottie-react";
import HeroImage from "@assets/img/Radar.json";
import HeroImageMobile from "@assets/img/Radar-mobile.png";
import "./Hero.scss";

const Hero: React.FC = () => {
  return (
    <>
      <div className="Hero-section">
        <div className="container-slogan">
          <div className="slogan-title">
            Streamline Sourcing, Empowering Growth, Unlock Full Potentials
          </div>
          <p className="mb-0">
            Transforming potential into performance through innovative career
            development, seamless recruitment, and strategic branding solutions.
          </p>
        </div>
        <Lottie
          animationData={HeroImage}
          loop={true}
          className="Hero-image"
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
        <div className="Hero-mobile">
          <img src={HeroImageMobile} />
        </div>
      </div>
    </>
  );
};

export default Hero;
