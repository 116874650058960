import React from "react";
import "./WhyAuto.scss";
import Lottie from "lottie-react";
import topImg from "@assets/img/top.json";
import searchImg from "@assets/img/search.json";
import automateImg from "@assets/img/automate.json";

const WhyAuto: React.FC = () => {
  return (
    <div className="why-auto-section">
      <div className="section-title text-center mb-4">Why Automation?</div>
      <div className="why-auto-content">
        <div className="row mt-3 g-2">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto-icon">
              <div className="why-icon-wrap">
                <Lottie
                  animationData={topImg}
                  loop={true}
                  style={{
                    objectFit: "cover",
                    width: "400px",
                  }}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto">
              <div className="why-auto-title">
                <span>Save</span> recruiters’ efforts in the talent sourcing
                process with an automated search engine
              </div>
              <div className="why-auto-description">
                Our talent sourcing automation technology allows you to search
                and filter through large candidate databases quickly using
                advanced search parameters like job titles, skills, keywords,
                and location. This ensures you find the most relevant candidates
                for your open positions in a fraction of the time compared to
                manual searching.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto">
              <div className="why-auto-title">
                Build <span>diverse pipelines</span> of potential candidates
                with advanced search capabilities
              </div>
              <div className="why-auto-description">
                Automated sourcing technology also helps aggregate candidate
                profiles from various platforms, including social media, job
                boards, and professional networks. By tapping into these diverse
                sources, recruiters can discover talent that may not be actively
                seeking new opportunities but could be a great fit for their
                organization.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto-icon">
              <div className="why-icon-wrap">
                <Lottie
                  className="rounded-circle"
                  animationData={searchImg}
                  loop={true}
                  style={{
                    objectFit: "cover",
                    width: "400px",
                  }}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto-icon">
              <div className="why-icon-wrap last">
                <Lottie
                  animationData={automateImg}
                  loop={true}
                  style={{
                    objectFit: "cover",
                    width: "300px",
                  }}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="why-auto">
              <div className="why-auto-title">
                Approach the <span>best</span> matched talents just after a few
                minutes
              </div>
              <div className="why-auto-description">
                The technology not only identifies potential candidates but also
                ranks them according to their suitability for the role. This
                ranking is based on historical data and predictive analytics,
                allowing recruiters to focus on the most promising candidates
                almost immediately after initiating a search.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyAuto;
