import React, { useEffect, useState } from "react";
import "./Filter.scss";
import CampaignModal from "@components/Recruiter/CampaignModal/CampaignModal";
import { message } from "antd";
import Helpers from "@utils/Helpers";
import IndustryFilter from "./IndustryFilter";
import PositionFilter from "./PositionFilter";
import LocationFilter from "./LocationFilter";
import OtherFilter from "./OtherFilter";
import LocationApi from "services/LocationApi";
import businessLogicApi from "services/BusinessLogicApi";

interface TFilter {
  campaignId: any;
  visible?: boolean;
  setCurrentStep?: (step: number) => void;
  industriesKeyword?: any;
  setIndustriesKeyword?: (data: []) => void;
  positionsKeyword?: any;
  setPositionsKeyWord?: (data: []) => void;
  setPusherEventCrawl: (data: string) => void;
  setPusherChannelCrawl: (data: string) => void;
}

const Filter: React.FC<TFilter> = ({
  visible,
  setCurrentStep,
  industriesKeyword,
  setIndustriesKeyword,
  positionsKeyword,
  setPositionsKeyWord,
  setPusherChannelCrawl,
  setPusherEventCrawl,
  campaignId,
}) => {
  // UI State
  const [showLocationInput, setShowLocationInput] = useState(false);
  const [showIndustryInput, setShowIndustryInput] = useState(false);
  const [showPositionInput, setShowPositionInput] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  // Data & UI State
  const [tempIndustry, setTempIndustry] = useState("");
  const [tempPosition, setTempPosition] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [tempOther, setTempOther] = useState("");
  // Data & UI Dropdown State
  const [industrySuggestion, setIndustrySuggestion] = useState(false);
  const [locationSuggestions, setLocationSuggestion] = useState(false);
  // Data State
  const [locationList, setLocationList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  // UI State
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [matchedSuggestions, setMatchedSuggestions] = useState([]);
  // Script 110 Payload State
  const [keywords, setKeywords] = useState({
    industries: [],
    positions: [],
    locations: [{ title: "Vietnam", value: true }],
    others: [],
  });
  const notification = Helpers.notification;
  const businessPayload = Helpers.businessPayload;

  // CHANGE DATA STRUCTURE TO TRUE FALSE FOR DISPLAY CHECKBOX
  useEffect(() => {
    const industriesArr = industriesKeyword.reduce(
      (acc: any, industry: any) => {
        const item = { title: Object.values(industry)[0], value: true };
        acc.push(item);
        return acc;
      },
      []
    );

    const positionsArr = positionsKeyword.reduce((acc: any, position: any) => {
      const item = { title: position, value: true };
      acc.push(item);
      return acc;
    }, []);

    setKeywords((prevKeywords) => ({
      ...prevKeywords,
      industries: industriesArr,
      positions: positionsArr,
    }));
  }, [industriesKeyword]);

  // GET INDUSTRY & LOCATION LIST
  useEffect(() => {
    if (visible) {
      const fetchLocationAndIndustry = async () => {
        try {
          const response = await LocationApi.getLocationAndIndustry();
          if (response.status === 200) {
            setIndustryList(response.data.industries);
            setLocationList(response.data.locations);
          }
        } catch (error) {
          message.error("Something wrong in server. Please try again");
        }
      };
      fetchLocationAndIndustry();
    }
  }, [visible]);

  // REMOVE INDUSTRY & LOCATION ITEM IN LOCATION & INDUSTRY LIST WHICH EXISTING UI
  useEffect(() => {
    if (industryList.length > 0) {
      const filteredA = industryList.filter((itemA) => {
        const titleA = Object.values(itemA)[0];
        return !keywords.industries.some(
          (itemB: any) => itemB.title === titleA
        );
      });
      setIndustryList(filteredA);
    }
    if (locationList.length > 0) {
      const filteredA = locationList.filter((itemA) => {
        const titleA = Object.values(itemA)[0];
        return !keywords.locations.some((itemB: any) => itemB.title === titleA);
      });
      setLocationList(filteredA);
    }
  }, [keywords.industries, keywords.locations]);

  // HANDLE CLOSE DROPDOWN WHEN CLIENT CLICKS OUTSIDE THE DROPDOWN
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const industry_input_element = document.querySelector(".industry-input");
      const industry_suggestion_element = document.querySelector(
        ".industry-suggestion"
      );
      const location_input_element = document.querySelector(".location-input");
      const location_suggestion_element = document.querySelector(
        ".location-suggestion"
      );
      const position_input_element = document.querySelector(".position-input");
      const other_input_element = document.querySelector(".other-input");

      if (
        industry_input_element &&
        !industry_input_element.contains(event.target) &&
        industry_suggestion_element &&
        !industry_suggestion_element.contains(event.target)
      ) {
        setIndustrySuggestion(false);
        setShowIndustryInput(false);
      }
      if (
        location_input_element &&
        !location_input_element.contains(event.target) &&
        location_suggestion_element &&
        !location_suggestion_element.contains(event.target)
      ) {
        setLocationSuggestion(false);
        setShowLocationInput(false);
      }
      if (
        position_input_element &&
        !position_input_element.contains(event.target)
      ) {
        setShowPositionInput(false);
      }
      if (other_input_element && !other_input_element.contains(event.target)) {
        setShowOtherInput(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // HANDLE ON/OF KEYWORD CHECKBOX
  const handleCheckboxChange = (index: number, key: string) => {
    switch (key) {
      case "industry":
        const newIndustries: any = [...keywords.industries];
        newIndustries[index].value = !newIndustries[index].value;
        setKeywords({
          ...keywords,
          industries: newIndustries,
        });
        break;
      case "position":
        const newPositions: any = [...keywords.positions];
        newPositions[index].value = !newPositions[index].value;
        setKeywords({
          ...keywords,
          positions: newPositions,
        });
        break;
      case "location":
        const newLocations: any = [...keywords.locations];
        newLocations[index].value = !newLocations[index].value;
        setKeywords({
          ...keywords,
          locations: newLocations,
        });
        break;
      default:
        const newOthers: any = [...keywords.others];
        newOthers[index].value = !newOthers[index].value;
        setKeywords({
          ...keywords,
          others: newOthers,
        });
        break;
    }
  };

  // NAVIGATE TO PREVSTEP
  const prevStep = () => {
    setCurrentStep?.(0);
  };

  // DETECT ON/OFF DROPDOWN OF INDUSTRY & LOCATION
  const onInputSuggestion = (type: string) => {
    if (type === "industry") {
      setIndustrySuggestion(true);
    } else {
      setLocationSuggestion(true);
    }
  };

  //  HANDLE ADD KEYWORD
  const handleAddKeyword = (event: any, type: string) => {
    if (event.key === "Enter") {
      if (type === "position") {
        const newList: any = [...keywords.positions];
        newList.push({ title: event.target.value, value: true });
        setKeywords({
          ...keywords,
          positions: newList,
        });
        setShowPositionInput(false);
        setTempIndustry("");
      } else {
        const newList: any = [...keywords.others];
        newList.push({ title: event.target.value, value: true });
        setKeywords({
          ...keywords,
          others: newList,
        });
        setShowOtherInput(false);
        setTempOther("");
      }
    }
    if (type === "industry") {
      const newList: any = [...keywords.industries];
      newList.push({
        title: event.currentTarget.getAttribute("data-name"),
        value: true,
      });
      setKeywords({
        ...keywords,
        industries: newList,
      });
      setShowIndustryInput(false);
      setIndustrySuggestion(false);
      setTempIndustry("");
    } else if (type === "location") {
      const newList: any = [...keywords.locations];
      newList.push({
        title: event.currentTarget.getAttribute("data-name"),
        value: true,
      });
      setKeywords({
        ...keywords,
        locations: newList,
      });
      setShowLocationInput(false);
      setLocationSuggestion(false);
      setTempLocation("");
    }
  };

  // FORMAT TO OBJECT ARRAY WITH 4 ARRAYS
  const transformKeywords = (): any => {
    return Object.fromEntries(
      Object.entries(keywords).map(([key, valueArray]: any) => [
        key,
        valueArray
          .filter((item: any) => item.value)
          .map((item: any) => item.title),
      ])
    );
  };

  // FORMAT PAYLOAD FOR 110 SCRIPT
  const linkedinKeywordsToXray = (
    industries = [],
    relatedPositions = [],
    locations = [],
    additionalKeywords = []
  ) => {
    const formatIndustry = (industry: any) => {
      if (industry.includes(",")) {
        return industry
          .split(",")
          .map((inds: any) => `"${inds.trim()}"`)
          .join(" | ");
      }
      return `"${industry}"`;
    };

    let queryParts = [];

    if (locations.length > 0) {
      let locationQuery = locations
        .map((location) => `intext:"${location}"`)
        .join(" | ");
      // Only add if locationQuery is not empty
      if (locationQuery) {
        queryParts.push(`site:linkedin.com/in/ +(${locationQuery})`);
      }
    }

    if (relatedPositions.length > 0) {
      let related = relatedPositions
        .map((position) => `"${position}"`)
        .join(" | ");
      if (related) {
        queryParts.push(`(${related})`);
      }
    }

    if (industries.length > 0) {
      let industry = industries.map((inds) => formatIndustry(inds)).join(" | ");
      if (industry) {
        queryParts.push(`(${industry})`);
      }
    }

    if (additionalKeywords.length > 0) {
      let additionalKeyword = additionalKeywords
        .map((keyword) => `"${keyword}"`)
        .join(" | ");
      if (additionalKeyword) {
        queryParts.push(`(${additionalKeyword})`);
      }
    }

    // Add the fixed part of the query
    const fixedQuery =
      ' +(intext:"open to work" OR intext:"open-to-work" OR intext:"Looking for") -HR -TA -"Talent Acquisition" -"Human Resource"';

    // Combine all parts into the final query string
    let query = queryParts.join(" AND ") + fixedQuery;

    return query;
  };

  // HANDLE SEARCH INDUSTRIES & LOCATIONS
  const handleSearch = (value: any, type: any) => {
    type === "industry" ? setTempIndustry(value) : setTempLocation(value);
    const query = value.toLowerCase();
    var matchedSuggestionsTemp: any = [];
    if (type === "industry") {
      industryList.forEach(function (industry) {
        const industryKey = Object.keys(industry)[0];
        const industryValue: string = industry[industryKey];
        if (industryValue.toLowerCase().includes(query)) {
          matchedSuggestionsTemp.push(industry);
        }
      });
    } else {
      locationList.forEach(function (location) {
        const locationKey = Object.keys(location)[0];
        const locationValue: string = location[locationKey];
        if (locationValue.toLowerCase().includes(query)) {
          matchedSuggestionsTemp.push(location);
        }
      });
    }
    setMatchedSuggestions(matchedSuggestionsTemp);
  };

  // STORE KEYWORD INTO DB
  const storeKeyword = async () => {
    const transformKey = transformKeywords();
    const payload = {
      domain_keywords: [...transformKey.industries, ...transformKey.positions],
      custom_keywords: transformKey.others,
      locations: transformKey.locations,
    };
    try {
      const response = await businessLogicApi.StoreKeyword(payload, campaignId);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      notification.error("Something wrong in server");
      return false;
    }
  };

  // START TO CRAWL
  const crawData = async () => {
    storeKeyword();
    const transformKey = transformKeywords();
    const query = linkedinKeywordsToXray(
      transformKey.industries,
      transformKey.positions,
      transformKey.locations,
      transformKey.others
    );

    try {
      const response = await businessLogicApi.Script_110(
        businessPayload.script(110, { query }),
        campaignId
      );
      if (response.status === 200) {
        setPusherEventCrawl(response.data.pusher_event_name);
        setPusherChannelCrawl(response.data.pusher_channel_name);
        setCurrentStep?.(2);
      }
    } catch (error) {
      notification.error("Something wrong in server. Please try again");
    }
  };

  return (
    <>
      <div
        className={`container-fluid m-0 ${visible !== true ? "d-none" : ""}`}
      >
        <div className="title-label m-1 text-center">
          Choose more detailed filteringcriteria for better match.
        </div>
        <div
          className="card-filtering"
          style={{ height: "85%", overflowY: "auto" }}
        >
          <IndustryFilter
            keywords={keywords}
            handleAddKeyword={handleAddKeyword}
            handleCheckboxChange={handleCheckboxChange}
            showIndustryInput={showIndustryInput}
            setShowIndustryInput={setShowIndustryInput}
            tempIndustry={tempIndustry}
            handleSearch={handleSearch}
            onInputSuggestion={onInputSuggestion}
            industrySuggestion={industrySuggestion}
            setIndustrySuggestion={setIndustrySuggestion}
            matchedSuggestions={matchedSuggestions}
          />
          <PositionFilter
            keywords={keywords}
            handleAddKeyword={handleAddKeyword}
            handleCheckboxChange={handleCheckboxChange}
            showPositionInput={showPositionInput}
            setShowPositionInput={setShowPositionInput}
            tempPosition={tempPosition}
            setTempPosition={setTempPosition}
          />
          <LocationFilter
            keywords={keywords}
            handleCheckboxChange={handleCheckboxChange}
            showLocationInput={showLocationInput}
            setShowLocationInput={setShowLocationInput}
            tempLocation={tempLocation}
            matchedSuggestions={matchedSuggestions}
            handleSearch={handleSearch}
            locationSuggestions={locationSuggestions}
            setLocationSuggestion={setLocationSuggestion}
            onInputSuggestion={onInputSuggestion}
            handleAddKeyword={handleAddKeyword}
          />
          <OtherFilter
            keywords={keywords}
            handleCheckboxChange={handleCheckboxChange}
            handleAddKeyword={handleAddKeyword}
            showOtherInput={showOtherInput}
            setShowOtherInput={setShowOtherInput}
            tempOther={tempOther}
            setTempOther={setTempOther}
          />
        </div>
        <div className="mt-auto px-0 pb-0">
          <div className="form-submit">
            <button
              className="px-3 py-2 btn-back text-secondary"
              onClick={prevStep}
            >
              Back
            </button>
            <button
              className="btn-heppai btn-continue px-3 py-2"
              onClick={crawData}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <CampaignModal
        isOpen={isCampaignModalOpen}
        cancel={() => setIsCampaignModalOpen(false)}
        submit={crawData}
      />
    </>
  );
};

export default Filter;
