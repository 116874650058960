import React, { useEffect, useRef, useState } from "react";
import Hero from "@components/Home/Hero/Hero";
import Services from "@components/Home/Services/Services";
import Outro from "@components/Home/Outro/Outro";
import videoIntro from "@assets/videos/heppai.mp4";
import "./LandingPage.scss";

const LandingPage: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedData = () => {
      if (videoElement) {
        videoElement.removeAttribute("muted");
        videoElement.play();
      }
    };

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, []);

  return (
    <>
      <Hero />
      <div className="intro-video-section">
        <video
          ref={videoRef}
          className="w-100 video-intro"
          src={videoIntro}
          playsInline
          autoPlay
          muted
          controls
        />
      </div>
      <Services />
      <Outro />
    </>
  );
};

export default LandingPage;
