import React, { useState } from "react";
import Header from "@components/Admin/Header/Header";
import SideBar from "@components/Admin/SideBar/SideBar";
import { Outlet } from "react-router-dom";

const Admin: React.FC = () => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  return (
    <div style={{ backgroundColor: "#dde3ed", overflowY: "hidden" }}>
      <SideBar
        sidebarCollapse={sidebarCollapse}
        setSidebarCollapse={setSidebarCollapse}
      />
      <main
        className="main-admin bg-transparent py-3 px-5"
        style={{
          marginLeft: !sidebarCollapse ? "272px" : "90px",
          overflowX: "hidden",
          transition: "all 0.4s ease",
        }}
      >
        <Header />
        <div className="container-fluid p-0" style={{ marginTop: "20px" }}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Admin;
