import SubHero from "@components/Home/SubHero/SubHero";
import React from "react";
import './RecruiterPage.scss'
import TargetAudience from "@components/Home/TargetAudience/TargetAudience";
import WhyAuto from "@components/Home/WhyAuto/WhyAuto";
import Contact from "@components/Home/Contact/Contact";

const RecruiterPage: React.FC = () => {
    return (
        <>  
            <div className="subhero-audience-section">
                <SubHero/>
                <TargetAudience />
            </div>
            <WhyAuto/>
            <Contact/>
        </>
    )
}

export default RecruiterPage;
