import React, { useState } from "react";
import "./SignIn.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Input, Spin } from "antd";
import AuthenticateApi from "services/AuthenticateApi";
import Helpers from "@utils/Helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import imgLogo from "@assets/img/logo/logo-only.svg";
import { useAuth } from "AuthContext";
import google from "@assets/img/logo/google.svg";

const SignIn: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const notification = Helpers.notification;

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await AuthenticateApi.Login({ email, password });
      if (response.status == 200) {
        handleLoginSuccess(response);
      }
    } catch (responseError: any) {
      setLoading(false);
      switch (responseError.status) {
        case 401:
          notification.error("Wrong password");
          break;
        case 404:
          notification.error("Your email does not exist");
          break;
        default:
          notification.error("Sonething wrong in server");
      }
    }
  };

  const googleLoginHook = useGoogleLogin({
    onSuccess: async (credentialResponse: any) => {
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${credentialResponse.access_token}`,
          },
        }
      );

      const userInfo = await userInfoResponse.json();
      handleGoogleLogin(userInfo);
    },
    onError: (error: any) => {
      notification.error("Something wrong from Google. Please login manually");
    },
  });

  const handleGoogleLogin = async (user_info: object) => {
    setLoading(true);
    try {
      const response = await AuthenticateApi.GoogleLogin(user_info);
      if (response.status == 200) {
        handleLoginSuccess(response);
      }
    } catch (responseError) {
      setLoading(false);
      notification.error("Something wrong in server");
    }
  };

  const handleLoginSuccess = (response: any) => {
    notification.success("Login successfully");
    setLoading(false);
    Helpers.storeAccessToken(response.data.data.access_token);
    Helpers.storeRefreshToken(response.data.data.refresh_token);
    Helpers.storeUserInfo(response.data.data);
    login(response.data.data);
    navigate(Helpers.getLoginRoute());
  };

  return (
    <div className="SignIn col-lg-5 d-flex flex-column align-items-center bg-body-light">
      {loading && (
        <div id="overlay-loader">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 48, color: "#fff" }} spin />
            }
          />
        </div>
      )}
      <div className="p-4 w-100 flex-grow-1 d-flex align-items-center">
        <div className="w-100">
          <div className="text-center mb-5 login-group-title">
            <img src={imgLogo} width={70} />
            <h1 className="fw-bold m-0">Welcome to</h1>
            <div className="branch">
              HEPP<span>AI</span>
            </div>
            <div className="slogan">Unlock your potential!</div>
          </div>

          <div className="row g-0 justify-content-center">
            <div className="col-xl-8">
              <div className="mb-4">
                <Button
                  className="google-login-button" // Đặt class cho button
                  onClick={() => googleLoginHook()}
                >
                  <img src={google} width={35} className="img-fluid" />
                  Sign in with Google 🚀
                </Button>
              </div>
              <div className="login-divide">
                <Divider>or log in with email</Divider>
              </div>
              <div className="mb-4">
                <Input
                  value={email}
                  className="form-login py-3"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <Input
                  value={password}
                  type="password"
                  className="form-login py-3"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  className="text-muted fs-sm fw-medium d-block d-lg-inline-block mb-1 text-decoration-none small mt-2"
                  href="#"
                >
                  Forgot Password?
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3 mt-5">
                <Button className="signin-btn w-100" onClick={handleLogin}>
                  <i className="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i>
                  Sign In
                </Button>
              </div>
              <div className="remind-sign-up text-center">
                Are you new to HEPPAI?{" "}
                <Link className="text-decoration-none" to="/auth/register">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">
        <p className="fw-medium text-black-50 py-2 mb-0">
          <strong>HEPPAI 0.0.1</strong> &copy;{" "}
          <span data-toggle="year-copy"></span>
        </p>
        <ul className="list list-inline py-2 mb-0">
          <li className="list-inline-item">
            <a className="text-muted fw-medium" href="#">
              Legal
            </a>
          </li>
          <li className="list-inline-item">
            <a className="text-muted fw-medium" href="#">
              Contact
            </a>
          </li>
          <li className="list-inline-item">
            <a className="text-muted fw-medium" href="#">
              Terms
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SignIn;
