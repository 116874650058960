// src/api/api.js
import axios from 'axios';
import Helpers from '@utils/Helpers'

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_DJANGO_ALPHA_ENDPOINT,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Helpers.getAccessToken()}`
    },
});

// Interceptor for request
apiClient.interceptors.request.use(
    (config) => {
        if (config.headers.requiresToken) {
            const token = Helpers.getAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor for response
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error);
    }
);

export { apiClient };
