import React from "react";
import "./Contact.scss";
import { Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";

const Contact: React.FC = () => {
  return (
    <div className="contact-section">
      <div className="contact-wrap">
        <div className="container">
          <div className="section-title">Contact Us</div>
        </div>
        <div className="contact-content">
          <div className="container">
            <div className="row">
              <div className="invite-slogan col-6">
                Are you ready to innovate with us? Share your ideas and let's
                embark on a journey toward transforming talent sourcing! For
                consultation, please indicate your query in the message box, and
                we'll respond within 2 business days. We're excited to connect
                and explore future possibilities!
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="d-flex gap-3 mb-4">
            <div className="firstname-group flex-fill">
              <Title level={5}>First name (Required)</Title>
              <Input size="large" />
            </div>
            <div className="lastname-group flex-fill">
              <Title level={5}>First name (Required)</Title>
              <Input size="large" />
            </div>
          </div>
          <div className="email-group mb-4">
            <Title level={5}>Email (Required)</Title>
            <Input size="large" />
          </div>
          <div className="message-group">
            <Title level={5}>Message (Required)</Title>
            <TextArea
              className="mb-5"
              rows={5}
              size="large"
            />
          </div>
          <div className="btn-submit">
            <button>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
