import React, { useState } from "react";
import "./TalentSourcing.scss";
import Detect from "./Detect/Detect";
import Filter from "./Filter/Filter";
import Result from "./Result/Result";
import { Steps } from "antd";

const TalentSourcing: React.FC = () => {
  const [currentStep, setCurentStep] = useState(0);
  const [industriesKeyword, setIndustriesKeyword] = useState([]);
  const [positionsKeyword, setPositionsKeyWord] = useState([]);
  const [pusherEventCraw, setPusherEventCrawl] = useState("");
  const [pusherChannelCrawl, setPusherChannelCrawl] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [campaignId, setCampaignId] = useState(null);

  const steps = [
    {
      title: "Detect",
      description: "industries & positions.",
    },
    {
      title: "Filter",
      description: "item which is detected.",
    },
    {
      title: "Result",
      description: "Result & Evaluation.",
    },
  ];
  return (
    <div className="TalentSourcing card">
      <div className="card-header d-flex align-items-center" id="card-talent">
        <div className="group-header">
          <div className="title-group">
            <div className="page-title">Talent Sourcing</div>
            <div className="page-subtitle">
              Please enter complete information to benefit employers in finding
              potential candidates
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3" id="talent-sourcing-body">
        <div className="container px-5">
          <Steps
            size="small"
            className="Heppai-step"
            current={currentStep}
            items={steps}
          />
        </div>
        <div id="multi-step-form" className="step-group h-100 mt-4">
          <div className="step step-1 step-input">
            <Detect
              visible={currentStep === 0}
              setCurrentStep={setCurentStep}
              industriesKeyword={industriesKeyword}
              setIndustriesKeyword={setIndustriesKeyword}
              positionsKeyword={positionsKeyword}
              setPositionsKeyword={setPositionsKeyWord}
              aboutCompany={aboutCompany}
              setAboutCompany={setAboutCompany}
              jobDescription={jobDescription}
              setJobDescription={setJobDescription}
              campaignId={campaignId}
              setCampaignId={setCampaignId}
            />
          </div>
          <div className="step step-2 step-input">
            <Filter
              visible={currentStep === 1}
              setCurrentStep={setCurentStep}
              industriesKeyword={industriesKeyword}
              setIndustriesKeyword={setIndustriesKeyword}
              positionsKeyword={positionsKeyword}
              setPositionsKeyWord={setPositionsKeyWord}
              setPusherChannelCrawl={setPusherChannelCrawl}
              campaignId={campaignId}
              setPusherEventCrawl={setPusherEventCrawl}
            />
          </div>
          <div className="step step-3 step-input">
            <Result
              pusherChannelCrawl={pusherChannelCrawl}
              pusherEventCrawl={pusherEventCraw}
              visible={currentStep === 2}
              campaignId={campaignId}
              jobDescription={jobDescription}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentSourcing;
