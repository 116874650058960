import Pusher from "pusher-js";

const pusherInfo = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
  forceTLS: true,
  authEndpoint: process.env.REACT_APP_BUSINESS_LOGIC_ENDPOINT + "/pusher/auth",
});

interface IPusher {
  pusher_channel_name: string;
  pusher_event_name: string
}

const pusher = (data: IPusher, funcCallback: (message: any) => void) => {
  const channel = pusherInfo.subscribe(data.pusher_channel_name);
  channel.bind(data.pusher_event_name, (message: any) => {
    if (message) {
      funcCallback(message);
    }
  });
};

export default pusher;
