import React from "react";
import "./TargetAudience.scss";
import target1 from "@assets/img/target-audience-1.svg";
import target2 from "@assets/img/target-audience-2.svg";
import target3 from "@assets/img/target-audience-3.svg";
import target4 from "@assets/img/target-audience-4.svg";

const TargetAudience: React.FC = () => {
  return (
    <div className="target-audience-section">
      <div className="container">
        <div className="section-title text-center mb-4">Target Audiences</div>
        <div className="section-subtitle text-center">
          Whether you're an independent recruiter looking to enhance your
          efficiency, a start-up building a stellar team, an established
          recruiting team seeking to streamline collaboration, or a staffing
          agency aiming to optimize client satisfaction, our talent sourcing
          automation help caters to all your talent acquisition needs.
        </div>
        <div className="target-audience-content mt-4">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="audience-item">
                <img className="audience-icon" src={target1} />
                <div className="audience-title">Independent Recruiters</div>
                <div className="audience-content">
                  Are you an independent recruiter seeking to optimize your
                  hiring process and deliver exceptional results for your
                  clients? Our talent sourcing automation empowers you with
                  advanced candidate sourcing capabilities, streamlined
                  screening tools, and insightful analytics to identify and
                  engage the best talent, all while maximizing your efficiency
                  and productivity.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="audience-item">
                <img className="audience-icon" src={target2} />
                <div className="audience-title">Start-ups</div>
                <div className="audience-content">
                  For emerging businesses tackling the challenges of talent
                  recruitment, we provide a distinct advantage. Our cutting-edge
                  automation-driven hiring solution empowers you to assemble an
                  exceptional team from the outset, guaranteeing that each new
                  addition resonates seamlessly with your organization’s mission
                  and values.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="audience-item">
                <img className="audience-icon" src={target3} />
                <div className="audience-title">
                  Internal Recruitment/ Talent Acquisition Team
                </div>
                <div className="audience-content">
                  Managing a dynamic recruitment team? We enhance teamwork,
                  communication, and decision-making, empowering your team to
                  unite towards shared hiring objectives. Eliminate barriers and
                  embrace efficient recruitment with our automation-driven
                  platform.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="audience-item">
                <img className="audience-icon" src={target4} />
                <div className="audience-title">
                  Staffing/ Headhunting Agencies
                </div>
                <div className="audience-content">
                  Your agency's growth relies on your capacity to swiftly and
                  accurately connect candidates with clients. Our cutting-edge
                  automation technology streamlines your recruitment efforts by
                  facilitating data-driven placements. This not only enhances
                  client satisfaction and loyalty but also boosts your agency's
                  profitability.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetAudience;
