import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import analyzeImg from "@assets/img/analyze.svg";
import "./Detect.scss";
import pusher from "@utils/Pusher";
import { Progress } from "antd";
import Helpers from "@utils/Helpers";
import "animate.css";
import businessLogicApi from "services/BusinessLogicApi";

interface TDetect {
  visible?: boolean;
  setCurrentStep?: (step: number) => void;
  industriesKeyword?: any;
  setIndustriesKeyword: (data: []) => void;
  positionsKeyword?: any;
  setPositionsKeyword: (data: []) => void;
  aboutCompany: string;
  setAboutCompany: (data: string) => void;
  jobDescription: string;
  setJobDescription: (data: string) => void;
  campaignId: any;
  setCampaignId: (campaign: any) => void;
}

const Detect: React.FC<TDetect> = ({
  visible,
  setCurrentStep,
  industriesKeyword,
  setIndustriesKeyword,
  positionsKeyword,
  setPositionsKeyword,
  aboutCompany,
  setAboutCompany,
  jobDescription,
  setJobDescription,
  campaignId,
  setCampaignId,
}) => {
  const [loading, setLoading] = useState(false);
  const [percentLoading, setPercentLoading] = useState(0);
  const [isAnalyzeBtnVisible, setIsAnalyzeBtnVisible] = useState(true);
  const [isContinueBtnVisible, setIsContinueBtnVisible] = useState(false);
  const businessPayload = Helpers.businessPayload;
  const notification = Helpers.notification;

  const textAreaChange = (e: any, key: string) => {
    key === "aboutCompany"
      ? setAboutCompany(e.target.value)
      : setJobDescription(e.target.value);
    setIsAnalyzeBtnVisible(true);
    setIsContinueBtnVisible(false);
  };

  const clearTextArea = (key: string) => {
    key === "aboutCompany" ? setAboutCompany("") : setJobDescription("");
    setIsAnalyzeBtnVisible(true);
    setIsContinueBtnVisible(false);
  };

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setPercentLoading((percent) => {
          if (percent >= 100) {
            clearInterval(intervalId);
            return 100;
          }
          return percent + 1;
        });
      }, 230);

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const analyze = async () => {
    setIndustriesKeyword?.([]);
    setPositionsKeyword?.([]);
    setLoading(true);
    const isCampaignId = await createCampaign();
    if (isCampaignId === false) {
      setLoading(false);
      return;
    }
    try {
      const response = await businessLogicApi.Script_0(
        businessPayload.script(0, {
          company_description: aboutCompany,
          job_description: jobDescription,
        }),
        isCampaignId
      );
      if (response.status === 200) {
        handleTriggerPusher(response.data);
      }
    } catch (error) {
      setLoading(false);
      setPercentLoading(0);
      notification.error("Can not analyze. Please try again");
    }
  };

  const createCampaign = async () => {
    try {
      const response = await businessLogicApi.CreateCampaign({
        campaign_name: "",
      });
      if (response.status === 200) {
        setCampaignId(response.data.campaign_id);
      }
      return response.data.campaign_id;
    } catch (error) {
      notification.error("Can not create campaign. Please try again");
      return false;
    }
  };

  const handleTriggerPusher = (data: any) => {
    pusher(data, (message) => {
      setPositionsKeyword?.(JSON.parse(message.related_positions));
      setIndustriesKeyword?.(JSON.parse(message.related_industries));
      setIsAnalyzeBtnVisible(false);
      setIsContinueBtnVisible(true);
    });
  };

  const nextStep = () => {
    setCurrentStep?.(1);
  };

  const deleteKeyword = (value: any, key: string) => {
    if (key === "industry") {
      const newData: any = industriesKeyword.filter((item: any) => {
        return !Object.values(item).includes(value);
      });
      setIndustriesKeyword?.(newData);
    } else {
      var index = positionsKeyword.indexOf(value);
      if (index !== -1) {
        const newData: any = [...positionsKeyword];
        newData.splice(index, 1);
        setPositionsKeyword(newData);
      }
    }
  };

  return (
    <div className={`row mt-5 ${visible !== true ? "d-none" : ""}`}>
      <div className="col-5">
        <div className="d-flex flex-column gap-4">
          <div className="form-group-area">
            <button
              className="btn-clear"
              onClick={(): void => clearTextArea("aboutCompany")}
            >
              <i className="small text-muted fa-solid fa-xmark"></i>
            </button>
            <div className={`form-title ${aboutCompany ? "active" : ""}`}>
              About Your Company
            </div>
            <TextArea
              rows={10}
              className="w-100 form-area"
              value={aboutCompany}
              onFocus={() => {
                aboutCompany === "" && setAboutCompany(" ");
              }}
              onBlur={() => {
                aboutCompany === " " && setAboutCompany("");
              }}
              onChange={(e: any) => textAreaChange(e, "aboutCompany")}
            />
          </div>
          <div className="form-group-area">
            <div className={`form-title ${jobDescription ? "active" : ""}`}>
              Job Description
            </div>
            <TextArea
              rows={10}
              className="w-100 form-area"
              value={jobDescription}
              onFocus={() => {
                jobDescription === "" && setJobDescription(" ");
              }}
              onBlur={() => {
                jobDescription === " " && setJobDescription("");
              }}
              onChange={(e: any) => textAreaChange(e, "jobDescription")}
            />
            <button
              className="btn-clear"
              onClick={(): void => clearTextArea("jobDescription")}
            >
              <i className="small text-muted fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`col-7 ${industriesKeyword.length !== 0 ? "" : "d-none"}`}
      >
        <div className="analyze-result-group h-100">
          <div className="analyze-result-domain">
            <div className="analyze-result-title mb-2">Industries</div>
            <div className="analyze-domain">
              {industriesKeyword.length > 0
                ? industriesKeyword.map((item: any, index: any) => {
                    const domainKey = Object.keys(item)[0];
                    const domainValue = item[domainKey];
                    return (
                      <div
                        className="analyze-result-item"
                        data-item={domainValue}
                        key={index}
                      >
                        <div className="keyword-item">
                          {domainValue}
                          <span
                            onClick={() =>
                              deleteKeyword(domainValue, "industry")
                            }
                            className="btn-remove-keyword text-muted small"
                          >
                            <i className="fa-solid fa-xmark text-muted"></i>
                          </span>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="analyze-result-position">
            <div className="analyze-result-title mb-2">Positions</div>
            <div className="analyze-position">
              {positionsKeyword.length > 0
                ? positionsKeyword.map((item: any, index: any) => {
                    return (
                      <div
                        className="analyze-result-item"
                        key={index}
                        data-item={item}
                      >
                        <div className="keyword-item">
                          {item}
                          <span
                            onClick={() => deleteKeyword(item, "position")}
                            className="btn-remove-keyword text-muted small"
                          >
                            <i className="fa-solid fa-xmark text-muted"></i>
                          </span>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className={`col-7 ${industriesKeyword.length === 0 ? "" : "d-none"}`}>
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="analyze-review w-75 d-flex justify-content-center align-items-center flex-column gap-4">
            <img src={analyzeImg} alt="" />
            <div className="analyze-review-title text-center">
              In order to analyze company and job description, please enter the
              job description and about your company in the textarea section,
              and press the analyze button.
            </div>
            <Progress
              className={`progress-analyze ${
                loading ? "visible" : "invisible"
              }`}
              trailColor="transparent"
              strokeColor="#5ac6c6"
              percent={percentLoading}
              size="small"
              status="active"
            />
          </div>
        </div>
      </div>
      <div className="card-submit mt-3 px-2">
        <div className="form-submit d-flex">
          <button
            className={`me-auto btn-heppai btn-analyze ${
              isAnalyzeBtnVisible ? "visible" : "invisible"
            }`}
            onClick={analyze}
          >
            Analyze
          </button>
          <button
            className={`ms-auto btn-heppai px-4 py-2 fw-bold btn-continue ${
              isContinueBtnVisible ? "visible" : "invisible"
            }`}
            onClick={nextStep}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Detect;
