import React, { createContext, useState, useContext } from "react";
import Helpers from "@utils/Helpers";
import { useLocation, useNavigate } from "react-router-dom";

// Tạo một interface để quản lý trạng thái người dùng
interface AuthContextType {
  isAuthenticated: boolean;
  login: (userInfo: any) => void;
  logout: () => void;
  hasTalentPackage: boolean;
  hasRecruiterPackage: boolean;
  hasEmployerPackage: boolean;
  isAdmin: boolean;
}

// Khởi tạo AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(Helpers.isLoggedIn());
  const [hasTalentPackage, setHasTalentPackage] = useState(Helpers.isTalent);
  const [hasRecruiterPackage, setHasRecruiterPackage] = useState(
    Helpers.isRecruiter
  );
  const [hasEmployerPackage, setHasEmployerPackage] = useState(
    Helpers.isEmployer
  );
  const [isAdmin, setIsAdmin] = useState(Helpers.isAdmin);

  const login = (userInfo: any) => {
    setIsAuthenticated(true);
    
    const userPackages = {
      hasTalentPackage: userInfo.default_view == "Talent",
      hasRecruiterPackage: userInfo.default_view == "Recruiter",
      hasEmployerPackage: userInfo.default_view == "Employer",
      isAdmin: userInfo.role_id == 2,
    };

    // UPDATE PACKAGE
    setHasTalentPackage(userPackages.hasTalentPackage);
    setHasRecruiterPackage(userPackages.hasRecruiterPackage);
    setHasEmployerPackage(userPackages.hasEmployerPackage);
    setIsAdmin(userPackages.isAdmin);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setHasTalentPackage(false);
    setHasRecruiterPackage(false);
    setHasEmployerPackage(false);
    setIsAdmin(false);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        hasTalentPackage,
        hasRecruiterPackage,
        hasEmployerPackage,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
