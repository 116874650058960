import React from "react";
import { Outlet } from "react-router-dom";
import BgCover from "@components/Auth/BgCover/BgCover";

const Auth: React.FC = () => {
  return (
    <div id="page-container" style={{ height: "100vh" }}>
      <div className="row g-0 h-100">
        <BgCover />
        <Outlet />
      </div>
    </div>
  );
};

export default Auth;
