import React from "react";
import addBtn from "@assets/img/add.svg";

interface TLocationFilter {
  keywords: any;
  handleCheckboxChange: (index: any, type: any) => void;
  showLocationInput: boolean;
  setShowLocationInput: (value: boolean) => void;
  tempLocation: string;
  handleSearch: (value: any, type: string) => void;
  locationSuggestions: boolean;
  matchedSuggestions: any;
  setLocationSuggestion: (valus: boolean) => void;
  onInputSuggestion: (value: string) => void;
  handleAddKeyword: (e: any, type: string) => void;
}

const LocationFilter: React.FC<TLocationFilter> = ({
  keywords,
  handleCheckboxChange,
  showLocationInput,
  setShowLocationInput,
  tempLocation,
  handleSearch,
  matchedSuggestions,
  locationSuggestions,
  setLocationSuggestion,
  onInputSuggestion,
  handleAddKeyword,
}) => {
  return (
    <div className="location-filter mb-2">
      <div className="filter-label">Locations</div>
      <div className="form-filter" id="locationCheckboxes">
        {keywords.locations.length > 0 &&
          keywords.locations.map((item: any, index: any) => {
            return (
              <div className="form-check-item" key={index}>
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.title}
                    onChange={() => handleCheckboxChange(index, "location")}
                    checked={!!item.value}
                  />
                  <label className="form-check-label">{item.title}</label>
                </div>
              </div>
            );
          })}
        {!showLocationInput && (
          <div className="form-add" onClick={() => setShowLocationInput(true)}>
            <img src={addBtn} />
            <span>Add</span>
          </div>
        )}
        <div className={`form-input ${showLocationInput ? `show` : ""}`}>
          <input
            type="text"
            className={`location-input ${showLocationInput ? `show` : ""}`}
            placeholder="Enter Location"
            value={tempLocation}
            onChange={(e) => handleSearch(e.target.value, "location")}
            onInput={() => onInputSuggestion("location")}
          />
          <button
            className="btn-close small"
            onClick={() => setShowLocationInput(false)}
          ></button>
          <div
            className={`suggestions location-suggestion ${
              locationSuggestions ? "show" : ""
            }`}
            onBlur={() => setLocationSuggestion(false)}
          >
            {matchedSuggestions.length > 0 ? (
              matchedSuggestions.map((item: any, index: number) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                const regex = new RegExp(
                  `(${tempLocation})`,
                  "gi"
                );
                const itemValue = value.replace(
                  regex,
                  '<span class="text-match">$1</span>'
                );
                return (
                  <div
                    key={index}
                    className="suggestion-item"
                    data-name={value}
                    onClick={(event) => handleAddKeyword(event, 'location')} // Sử dụng onClick đúng cách trong React
                    dangerouslySetInnerHTML={{ __html: itemValue }} // Sử dụng để inject HTML an toàn
                  />
                );
              })
            ) : (
              <div style={{ display: "none" }}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationFilter;
