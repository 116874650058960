// src/api/userApi.js
import { apiClient } from './apiConfig';

const LocationApi = {
    getLocationAndIndustry: () => {
        return apiClient.get('/industry/location/get/');
    },
};

export default LocationApi;
