import cookie from "react-cookies";
import { message } from "antd";

const COOKIE_DOMAIN = "";
const COOKIE_ACCESS_TOKEN = "atk";
const COOKIE_REFRESH_TOKEN = "rtk";
const COOKIE_USER_INFO = "user_info";
const cookieSetting = {
  path: "/",
  domain: COOKIE_DOMAIN,
};

const setCookie = (name: string, value: string): void =>
  cookie.save(name, value, cookieSetting);
const getCookie = (name: string): string => cookie.load(name);
const removeCookie = (name: string): void => cookie.remove(name, cookieSetting);

class Helpers {
  getRefreshToken = (): string => getCookie(COOKIE_REFRESH_TOKEN);

  storeRefreshToken = (refreshToken: string): void =>
    setCookie(COOKIE_REFRESH_TOKEN, refreshToken);

  getAccessToken = (): string => getCookie(COOKIE_ACCESS_TOKEN);

  storeAccessToken = (accessToken: string): void =>
    setCookie(COOKIE_ACCESS_TOKEN, accessToken);

  clearToken = (): void => {
    removeCookie(COOKIE_REFRESH_TOKEN);
    removeCookie(COOKIE_ACCESS_TOKEN);
  };

  getUserInfo = (): any => {
    const userInfo: any = getCookie(COOKIE_USER_INFO);
    return userInfo;
  };

  storeUserInfo = (userInfo: object): void => {
    const userInfoString = JSON.stringify(userInfo);
    setCookie(COOKIE_USER_INFO, userInfoString);
  };

  removeUserInfo = (): void => {
    removeCookie(COOKIE_USER_INFO);
  };

  isLoggedIn = (): boolean => {
    return this.getAccessToken() !== undefined;
  };

  isAdmin = (): boolean => {
    return this.getUserInfo() && this.getUserInfo().role_id == 2;
  };

  isRecruiter = (): boolean => {
    return this.getUserInfo() && this.getUserInfo().default_view == "Recruiter";
  };

  isTalent = (): boolean => {
    return this.getUserInfo() && this.getUserInfo().default_view == "Talent";
  };

  isEmployer = (): boolean => {
    return this.getUserInfo() && this.getUserInfo().default_view == "Employer";
  };

  getLoginRoute = (): string => {
    var router = ''
    switch (this.getUserInfo().default_view) {
      case "Talent":
        router = '/talent/panel'
        break;
      case "Recruiter":
        router = "/recruiter/panel";
        break;
      case "Employer":
        router = "/";
        break;
    }
    return router;
  }

  notification = {
    success: (content: string) => {
      message.open({
        type: "success",
        content: content,
      });
    },
    error: (content: string) => {
      message.open({
        type: "error",
        content: content,
      });
    },
    warning: (content: string) => {
      message.open({
        type: "warning",
        content: content,
      });
    },
  };

  businessPayload = {
    script: (script_id: number, content: any) => {
      return {
        script_id: script_id,
        backend_instance_id: 1,
        content: content,
      };
    },
  };
}

const instance = new Helpers();
export default instance;
