// src/api/userApi.js
import { apiClient } from './apiConfig';

const businessLogicApi = {
    Script_0: (data: any, campaignId: any) => {
        return apiClient.post(`campaign/${campaignId}/task/`, data);
    },
    Script_110: (data: any, campaignId?: any) => {
        return apiClient.post(`campaign/${campaignId}/task/`, data);
    },
    Script_210: (data: any, campaignId?: any) => {
        return apiClient.post(`campaign/${campaignId}/task/`, data);
    },
    Script_310: (data: any, campaignId?: any) => {
        return apiClient.post(`campaign/${campaignId}/task/`, data);
    },
    CreateCampaign: (data: any) => {
        return apiClient.post('campaign/', data);
    },
    StoreKeyword: (data: any, campaignId: any) => {
        return apiClient.post(`campaign/${campaignId}/campaign_input/`, data);
    },
    StoreEvaluate: (data: any, campaignId: any) => {
        return apiClient.post(`campaign/${campaignId}/profile_evaluation/`, data);
    }
};

export default businessLogicApi;
