import React from "react";
import "./SubHero.scss";

const Hero: React.FC = () => {
  return (
    <>
      <div className="subhero-section">
        <div className="container-slogan">
          <div className="slogan-title">
            ENHANCE YOUR RECRUITMENT CAPABILITIES THROUGH AUTOMATION
          </div>
          <p className="mb-0">
            Easily discover potential candidates across various prominent social
            media platforms and professional networks with just a few clicks.
            Connect the best-matched talents in the fastest way.
          </p>
          <p>
            Reduce the time required to hire new employees and lower the
            expenses related to the recruitment process.
          </p>
          <button className="start-journey py-2 px-5">Start Your Journey</button>
        </div>
      </div>
    </>
  );
};

export default Hero;
