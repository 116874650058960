import React from "react";
import { Radar } from "react-chartjs-2";

interface TRadarChart {
  datasets: any;
}

const RadarChart: React.FC<TRadarChart> = ({ datasets }) => {
  const data = {
    labels: ["Overall", "Skill", "Education", "Language", "Experience"],
    datasets: [
      {
        label: "Score",
        data: datasets,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  const options = {
    scales: {
      r: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          display: false
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return <Radar data={data} options={options} />;
};

export default RadarChart;
