import React from "react";
import frameTalent from "@assets/img/Frame-talent.jpg";
import frameEmployer from "@assets/img/Frame-employer.jpg";
import recruiterVideo from "@assets/videos/recruiter.mp4";
import "./Services.scss";

const Services: React.FC = () => {
  return (
    <div className="services-section">
      <div className="container p-0">
        <div className="services-group px-2">
          <div className="section-title text-center">Who We Serve?</div>
          <div className="row mt-5">
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0">
              <img
                src={frameTalent}
                alt=""
                className="img-fluid service-image"
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0">
              <div className="d-flex align-items-center">
                <div className="service-talents-description">
                  <div className="service-title">Talents</div>
                  <div className="service-description-content">
                    For talents, HEPPAI provides a comprehensive suit of
                    services designed to support them in professional
                    development
                  </div>
                  <div className="service-description-title">
                    Profile Booster
                  </div>
                  <div className="service-description-content">
                    The platform offers a Profile Booster, which enhances the
                    visibility and appeal of user profiles to potential
                    employers
                  </div>
                  <div className="service-description-title">
                    Career Strategy Guild
                  </div>
                  <div className="service-description-content">
                    Additionally, HEPPAI delivers a Career Strategy Guild that
                    helps individuals navigate their career paths effectively,
                    providing tailored advice and insights
                  </div>
                  <div className="service-description-title">
                    Upskilling Programs
                  </div>
                  <div className="service-description-content">
                    HEPPAI's upskilling programs are designed to equip users
                    with the necessary competencies for their desired roles.
                  </div>
                  <div className="service-description-title">
                    Community Forums
                  </div>
                  <div className="service-description-content">
                    The platform also fosters a sense of community through
                    forums where talents can connect, share experiences, and
                    seek advice from peers.
                  </div>
                  <div className="service-description-title">Job Hub</div>
                  <div className="service-description-content">
                    Finally, HEPPAI includes a Job Hub that aggregates job
                    opportunities, making it easier for users to find relevant
                    positions that align with their career goals.
                  </div>
                  <button className="btn-coming-soon">Coming Soon</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0 order-1 order-lg-0">
              <div className="d-flex align-items-center">
                <div className="service-recruiters-description">
                  <div className="service-title">Recruiters</div>
                  <div className="service-description-content">
                    HEPPAI provides a comprehensive suite of services tailored
                    for recruiters, focusing on enhancing the efficiency and
                    effectiveness of the hiring process.
                  </div>
                  <div className="service-description-title">
                    Automated Talent Sourcing
                  </div>
                  <div className="service-description-content">
                    HEPPAI's automated talent sourcing technology leverages
                    advanced algorithms and tools to efficiently identify and
                    engage potential candidates, saving recruiters time and
                    allowing them to focus on high-quality hires.
                  </div>
                  <div className="service-description-title">
                    Comprehensive Candidate Profiling
                  </div>
                  <div className="service-description-content">
                    HEPPAI's commitment to diversity and inclusion is reflected
                    in its sourcing strategy, which involves leveraging a wide
                    range of open web sources to access a broader talent pool.
                    This approach ensures that recruiters can discover
                    candidates from diverse backgrounds, promoting equity and
                    enhancing the chances of finding hidden talent.
                  </div>
                  <div className="service-description-title">
                    Onboarding Support
                  </div>
                  <div className="service-description-content">
                    HEPPAI's AI-powered candidate evaluation system ensures that
                    recruiters can quickly identify and prioritize top talent.
                    By accurately matching candidates' skills and experiences to
                    specific job requirements, HEPPAI streamlines the hiring
                    process and helps companies make informed decisions. This
                    technology not only improves the quality of hires but also
                    accelerates time-to-hire, allowing recruiters to focus on
                    building high-performing teams.
                  </div>
                  <button className="btn-explore">
                    <a className="w-100 h-100" href="/recruiter">
                      Explore more
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0 order-0 order-lg-1">
              <div className="video-frame-recruiter">
                <video
                  className="img-fluid rounded-3"
                  src={recruiterVideo}
                  muted
                  autoPlay
                  controls
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0">
              <img
                src={frameEmployer}
                alt=""
                className="img-fluid service-image"
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 p-0">
              <div className="d-flex align-items-center">
                <div className="service-employers-description">
                  <div className="service-title">Employers</div>
                  <div className="service-description-content">
                    HEPPAI offers a comprehensive suit of services tailored to
                    enhance employer capabilities in several key areas.
                  </div>
                  <div className="service-description-title">
                    Team Performance Assessments
                  </div>
                  <div className="service-description-content">
                    HEPPAI's team performance assessments provide employers with
                    valuable insights into their workforce's effectiveness and
                    areas for improvement, enabling organizations to foster a
                    more productive and collaborative work environment.
                  </div>
                  <div className="service-description-title">
                    Training Programs
                  </div>
                  <div className="service-description-content">
                    In order to meet specific needs of organizations, HEPPAI
                    provides training programs designed to elevate employee
                    skills and knowledge. Thus, employers can enhance their
                    team's competencies and overall performance.
                  </div>
                  <div className="service-description-title">
                    Optimize Company Culture
                  </div>
                  <div className="service-description-content">
                    By implementing strategies that promote a positive and
                    inclusive work environment, HEPPAI helps organizations
                    cultivate a culture that drives employee engagement and
                    productivity.
                  </div>
                  <div className="service-description-title">
                    Personalized Career Plans
                  </div>
                  <div className="service-description-content">
                    By providing employees with tailored career paths and
                    support, HEPPAI helps organizations attract and retain the
                    best talent, while also fostering a culture of continuous
                    learning and growth.
                  </div>
                  <button className="btn-coming-soon">Coming Soon</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
