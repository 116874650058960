import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HeppaiRoute } from "./Route";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "AuthContext";

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <AuthProvider>
        <Router>
          <HeppaiRoute />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
