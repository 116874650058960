import React, { useEffect, useRef, useState } from "react";
import "./VerifySection.scss";

interface IVerifySectionProps {
  email: string;
  isVerifyOpen: boolean;
  verifyOTP: (otp: string) => void;
}

const VerifySection: React.FC<IVerifySectionProps> = ({
  email,
  isVerifyOpen,
  verifyOTP,
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [maskEmail, setMaskEmail] = useState("");

  // MASK EMAIL
  useEffect(() => {
    if (email != "") {
      const atIndex = email.indexOf("@");
      const firstPart = email.slice(0, atIndex);
      const lastPart = email.slice(atIndex);

      const maskedFirstPart =
        "*".repeat(firstPart.length - 2) + firstPart.slice(-2);
      setMaskEmail(maskedFirstPart + lastPart);
    }
  });

  //   FOCUS ON THE FIRST OTP INPUT WHEN MODAL IS VISIBLE
  useEffect(() => {
    if (isVerifyOpen) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 500);
    }
  }, [isVerifyOpen]);

  //   VERIFY OTP WHEN THE OTP ARRAY HAVE 6 DIGITS
  useEffect(() => {
    const isOtpComplete = otp.every((item) => item !== "");
    if (isOtpComplete) {
      const fullOtp = otp.join("");
      verifyOTP(fullOtp);
    }
  }, [otp]);

  //   FOCUS ON THE NEXT INPUT WHEN ENTERED ANY INPUT
  const handleChange = (element: any, index: any): any => {
    const value = element.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  //   DELETE ALL OF INPUTS WHEN PRESS BACKSPACE
  const handleKeyDown = (e: any, index: any) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      otp.map((item, index) => {
        newOtp[index] = "";
      });
      setOtp(newOtp);
      inputRefs.current[0]?.focus();
    }
  };

  // HANDLE PASTE EVENT
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text");

    if (/^\d{6}$/.test(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);
      inputRefs.current[5]?.focus();
    }
  };

  return (
    <div className="VerifySection row g-0 justify-content-center">
      <div className="col-xl-11">
        <div className="otp-container text-center">
          <h1 className="fw-bold mb-5">OTP Verification</h1>
          <p>Enter the 6-digit code sent to {maskEmail}.</p>
          <div className="otp-inputs mb-4 mt-3 w-100">
            {otp.map((value, index) => (
              <input
                className="otp-input"
                autoFocus={true}
                key={index}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <p className="mb-2">
            Didn't receive code?{" "}
            <a href="#" id="resend-link">
              Resend
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifySection;
