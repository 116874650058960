import React, { useState } from "react";
import Header from "@components/Recruiter/Header/Header";
import SideBar from "@components/Recruiter/SideBar/SideBar";
import { Outlet } from "react-router-dom";

const Recruiter: React.FC = () => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  return (
    <div style={{ backgroundColor: "#EFF9F9", overflowY: "hidden" }}>
      <SideBar
        sidebarCollapse={sidebarCollapse}
        setSidebarCollapse={setSidebarCollapse}
      />
      <main
        className="main-recruiter bg-transparent py-3 px-5"
        style={{
          marginLeft: !sidebarCollapse ? "272px" : "90px",
          transition: "all 0.4s ease",
        }}
      >
        <Header />
        <div className="container-fluid p-0 " style={{ marginTop: "20px", flex: 1 }}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Recruiter;
