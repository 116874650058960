import React from "react";
import "./Campaign.scss";
import { Button, Table, TableProps } from "antd";
interface TTableProps {
  key: string;
  taskId: number;
  campaign: string;
  point: string;
  hour: string;
  status: string;
  candidates: string;
  date: string;
  time: string;
}

const columns: TableProps<TTableProps>["columns"] = [
  {
    title: "Campaign",
    dataIndex: "campaign",
    key: "campaign",
    width: 250,
    render: (_, record) => (
      <div className="d-flex flex-column">
        <a className="fw-bold text-dark text-decoration-none">
          {record.campaign}
        </a>
        <span className="text-muted">ID: {record.taskId}</span>
      </div>
    ),
  },
  {
    title: "Point",
    dataIndex: "point",
    key: "point",
    width: 120,
  },
  {
    title: "Run Time",
    dataIndex: "hour",
    key: "hour",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      if (text == "processing") {
        return (
          <div className="campaign-status processing">
            <i className="fa-solid fa-spinner"></i>
            {text}
          </div>
        );
      } else if (text == "completed") {
        return (
          <div className="campaign-status completed">
            <i className="fa-solid fa-check"></i>
            {text}
          </div>
        );
      } else {
        return (
          <div className="campaign-status failed">
            <i className="fa-solid fa-xmark"></i>
            {text}
          </div>
        );
      }
    },
  },
  {
    title: "Candidates",
    dataIndex: "candidates",
    key: "candidates",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    render: (_, record) => {
      return (
        <div className="d-flex flex-column">
          <span className="text-muted">{record.time}</span>
          <a className="fw-bold text-dark text-decoration-none">
            {record.date}
          </a>
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    width: 70,
    render: (_, record) => {
      return (
        <Button className="action-btn">
          <i className="fa-solid fa-ellipsis"></i>
        </Button>
      );
    },
  },
];

const data: TTableProps[] = [
  {
    key: "1",
    taskId: 8739435,
    campaign: "John Brown",
    point: "32 points",
    hour: "00:05:12",
    status: "processing",
    candidates: "980",
    date: "11/02/2024",
    time: "08:30:52",
  },
  {
    key: "2",
    taskId: 1255635,
    campaign: "Jim Green",
    point: "42 points",
    hour: "00:01:32",
    status: "completed",
    candidates: "280",
    date: "10/05/2024",
    time: "22:30:52",
  },
  {
    key: "3",
    taskId: 9823873,
    campaign: "Joe Black",
    point: "32 points",
    hour: "00:00:12",
    status: "failed",
    candidates: "1280",
    date: "23/08/2024",
    time: "06:40:12",
  },
  {
    key: "4",
    taskId: 9823873,
    campaign: "Lip Daniel",
    point: "32 points",
    hour: "00:00:12",
    status: "processing",
    candidates: "1280",
    date: "23/08/2024",
    time: "06:40:12",
  },
  {
    key: "5",
    taskId: 9823873,
    campaign: "John Dow",
    point: "32 points",
    hour: "00:00:12",
    status: "completed",
    candidates: "1280",
    date: "23/08/2024",
    time: "06:40:12",
  },
  {
    key: "6",
    taskId: 9823873,
    campaign: "John Dow",
    point: "32 points",
    hour: "00:00:12",
    status: "failed",
    candidates: "1280",
    date: "23/08/2024",
    time: "06:40:12",
  },
];

const Campaign: React.FC = () => {
  return (
    <div className="Campaign">
      <div className="card header">
        <div
          className="card-header d-flex align-items-center"
          id="card-campaign"
        >
          <div className="group-header">
            <div className="title-group">
              <div className="page-title">Campaign List</div>
              <div className="page-subtitle">
                Manage your candidate search campaigns
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="search-filter-group bg-transparent">
        <div className="input-container w-25 me-auto">
          <span className="prefix">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
          <input
            type="text"
            className="form-control-search"
            placeholder="Search"
          />
        </div>
        <div className="group-filter gap-2">
          <div className="sort-action d-inline">
            <i className="fa-solid fa-arrow-down-wide-short"></i>
            {"  "}
            Sort
          </div>
          <div className="filter-action d-inline ms-4 gap-2">
            <i className="fa-solid fa-filter"></i>
            {"  "}
            Filter
          </div>
        </div>
      </div>
      <div className="campaign-table mt-4">
        <Table<TTableProps> columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default Campaign;
